import React from "react";
import Config from '../../../../config';
import propTypes from "prop-types"
import { Attachment } from 'diginet-core-ui/components';

class W09F2000Attachments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            attachments: []
        };
    }

    loadGrid = async () => {
        const {mode, onLoad} = this.props;
        if (onLoad) {
            await onLoad(mode, "", (error, data) => {
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                }
                this.setState({attachments:  data?.rows || []});
            });
        }
    };

    componentDidMount = async () => {
        await this.loadGrid();
    };

    render() {
        const {attachments} = this.state;

        return (
            <div style={{width: "100%"}}>
                <Attachment
                    data={attachments}
                    readOnly={true}
                />
            </div>
        );
    }

}

W09F2000Attachments.propTypes = {
    data: propTypes.any,
};

export default W09F2000Attachments;
