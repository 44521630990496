/**
 * @copyright 2019 @ DigiNet
 * @author THIEN
 * @create 1/6/2020
 * @Example
 */

import React, {Component} from "react";

import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import * as w09f2005Action from "../../../../redux/W0X/W09F2005/W09F2005_actions";
import * as generalActions from "../../../../redux/general/general_actions";

import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import {Row, Col, FormGroup} from "react-bootstrap";
import {FormLabel as Label} from "@material-ui/core";

import W09F2005Tabs from "./W09F2005Tabs";

import {browserHistory} from "react-router";
import GridContainer from "../../../grid-container/grid-container";
import {Column} from "devextreme-react/data-grid";

import Config from "../../../../config";

import Filter from "../../../filter/filter";
import moment from "moment";
import UserImage from "../../../common/user/user-image";
import { Button, DatePicker, Dropdown, Typography, TreeView } from 'diginet-core-ui/components';

const styles = {
    root: {
        marginBottom: 15,
        borderRadius: 0,
        boxShadow: "none",
        backgroundColor: "#F3F3F3"
    },
    labelCheckbox: {
        fontSize: "0.8rem"
    },
    checkbox: {
        padding: "0 4px"
    },
    tab: {
        maxWidth: "fit-content",
        padding: "15px 58px"
    },
    tabActive: {
        backgroundColor: "#fff"
    },
    btnFilter: {
        backgroundColor: "#9999993d",
        textTransform: "uppercase"
    },
    imgAvatar: {
        width: 20,
        minWidth: 20,
        height: 20,
        objectFit: 'cover',
        borderRadius: 20,
        marginRight: 8
    },
};

class W09F2005 extends Component {
    constructor(props) {
        super(props);
        const filter = Config.getStoreState(this);
        this.filterEmployees = {
            SearchValue: filter && filter.SearchValue ? filter.SearchValue : "",
            DatejoinFrom: filter && filter.DatejoinFrom ? filter.DatejoinFrom : "",
            DatejoinTo: filter && filter.DatejoinTo ? filter.DatejoinTo : "",
            OrgChartID: filter && filter.OrgChartID ? filter.OrgChartID : "",
            DutyID: filter && filter.DutyID ? filter.DutyID : "",
            EmployeeID: filter && filter.EmployeeID ? filter.EmployeeID : null,
            ProjectID: filter && filter.ProjectID ? filter.ProjectID : null,
            skip: filter && filter.skip ? filter.skip : 0,
            limit: filter && filter.limit ? filter.limit : 20,
            fieldSort: "",
            typeSort: ""
        };
        this.state = {
            iPermissions: {},
            iPerW09F2005: 0,
            iPerW09F2006: 0,
            iPerW09F2007: 0,
            listEmployee: {rows: [], total: 0},
            tabIndex: 0,
            loaded: true,
            dataCboEmployees: {
                rows: [],
                total: 0
            },
            dataCboProjects: {
                rows: [],
                total: 0
            },
            loading: {
                cboEmployees: false
            },

            filter: {
                SearchValue: this.filterEmployees.SearchValue,
                DatejoinFrom: this.filterEmployees.DatejoinFrom,
                DatejoinTo: this.filterEmployees.DatejoinTo,
                OrgChartID: this.filterEmployees.OrgChartID,
                DutyID: this.filterEmployees.DutyID,
                EmployeeID: this.filterEmployees.EmployeeID,
                ProjectID: this.filterEmployees.ProjectID,
            },
            dataOrgChart: []
        };
        this.loading = {
            cboEmployees: false
        };

        this.filterCboEmployees = {
            strSearch: "",
            skip: 0,
            limit: 50
        };
        this.filterCboProject = {
            strSearch: "",
            skip: 0,
            limit: 50
        };
        this.timer = null;
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission(["W09F2005", "W09F2006", "W09F2007"], (arrPer) => {
            if (arrPer !== 0) {
                let iPermissions = {};
                arrPer.forEach(per => {
                    if (per.hasOwnProperty('FormID') && per.hasOwnProperty('IsPermission')) {
                        iPermissions[per.FormID] = per.IsPermission;
                    }
                });
                this.setState({iPermissions: iPermissions});
                // const iPerW09F2005 = arrPer.filter(per => per.FormID === "W09F2005");
                // const iPerW09F2006 = arrPer.filter(per => per.FormID === "W09F2006");
                // const iPerW09F2007 = arrPer.filter(per => per.FormID === "W09F2006");
                // this.setState({
                //   iPerW09F2005: iPerW09F2005 && iPerW09F2005.length > 0 && iPerW09F2005[0].IsPermission ? iPerW09F2005[0].IsPermission : 0,
                //   iPerW09F2006: iPerW09F2006 && iPerW09F2006.length > 0 && iPerW09F2006[0].IsPermission ? iPerW09F2006[0].IsPermission : 0,
                //   iPerW09F2007: iPerW09F2007 && iPerW09F2007.length > 0 && iPerW09F2007[0].IsPermission ? iPerW09F2007[0].IsPermission : 0,
                // });
            }
        });
    };

    onLoadDataFilter = () => {
        this.loadDataOrgChart();
        this.loadCboDuty();
        this.loadCboEmployees();
        this.loadCboProjects();
    };

    onSearch = e => {
        const value = e?.target?.value ?? '';
        if (this.timer) clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.filterEmployees.SearchValue = value;
            this.loadListEmployee();
        }, 500);
    };

    onFilter = () => {
        this.loadListEmployee();
    };

    loadListEmployee = () => {
        const {tabIndex} = this.state;
        const {
            skip,
            limit,
            SearchValue,
            OrgChartID,
            DutyID,
            EmployeeID,
            ProjectID,
            DatejoinTo,
            DatejoinFrom,
            fieldSort,
            typeSort,
        } = this.filterEmployees;
        let paramFilters = {
            SearchValue,
            OrgChartID,
            DutyID,
            EmployeeID,
            ProjectID,
            DatejoinTo,
            DatejoinFrom,
            fieldSort,
            typeSort
        };
        let employeeType = tabIndex ? (tabIndex === 1 ? 0 : tabIndex) : 1;
        let params = {skip, limit, IsWorking: employeeType, ...paramFilters, Language: Config.language || "84"};
        this.setState({loaded: true});
        this.props.w09f2005Action.getListEmployees(
            params,
            (error, {total, rows}) => {
                if (error) {
                    let message = error.message || Config.lang("Loi_chua_xac_dinh");
                    Config.popup.show("INFO", message);
                    this.setState({loaded: false});
                    return false;
                }
                this.setState({listEmployee: {rows, total}}, () => {
                    this.setState({loaded: false});
                });
            }
        );
    };

    loadDataOrgChart = () => {
        this.props.generalActions.getOrgCharts({}, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const dataSource = this._generateDataOrg(data, "OrgChartID", "OrgChartParentID");
                this.setState({ dataOrgChart: dataSource });
            }
        });
    };

    _generateDataOrg = (dataSource, valueExpr, parentIdExpr) => {
        return dataSource.map((e) => {
            if (e[valueExpr] && e[parentIdExpr] && e[valueExpr] === e[parentIdExpr]) {
                delete e[parentIdExpr];
                e.expanded = true;
            } else if (!e[parentIdExpr]) {
                e.expanded = true;
            }
            return e;
        });
    };


    loadCboDuty = () => {
        const param = {Language: Config.language || "84"};
        this.props.generalActions.getCboDuty(param, error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadCboEmployees = (isReset) => {
        const param = {
            HostID: "",
            Type: "EmployeeID",
            FormID: "W09F2005",
            Language: Config.language || "84",
            skip: this.filterCboEmployees.skip,
            limit: this.filterCboEmployees.limit,
            search: this.filterCboEmployees.strSearch
        };
        this.loading.cboEmployees = true;
        this.setLoading();
        this.props.generalActions.getCboEmployees(param, (error, data) => {
            this.loading.cboEmployees = false;
            this.setLoading();
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const {dataCboEmployees} = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total: total
                    }
                })
            }
        });
    };

    loadCboProjects = (isReset) => {
        const param = {
            HostID: "",
            FormID: "W09F2005",
            Language: Config.language || "84",
            skip: this.filterCboProject.skip,
            limit: this.filterCboProject.limit,
            search: this.filterCboProject.strSearch
        };
        this.loading.cboProjects = true;
        this.setLoading();
        this.props.generalActions.getCboProjects(param, (error, data) => {
            this.loading.cboProjects = false;
            this.setLoading();
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const {dataCboProjects} = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboProjects: {
                        rows: isReset ? rows : dataCboProjects.rows.concat(rows),
                        total: total
                    }
                })
            }
        });
    };

    setLoading = () => {
        this.setState({loading: this.loading});
    };

    setFilter = () => {
        this.setState({filter: this.filterEmployees});
    };

    handleFilterChange(key, data) {
        if (!key) return false;
        switch (key) {
            case "dateForm":
                this.filterEmployees.DatejoinFrom = data.value;
                break;
            case "dateTo":
                this.filterEmployees.DatejoinTo = data.value;
                break;
            case "orgChartID":
                this.filterEmployees.OrgChartID = data ? data?.join(',') : '';
                break;
            case "dutyID":
                this.filterEmployees.DutyID = data.value ?? '';
                break;
            case "employeeID":
                this.filterEmployees.EmployeeID = data.value ?? '';
                break;
            case "projectID":
                this.filterEmployees.ProjectID = data.value ?? '';
                break;
            default:
                break;
        }
        this.setFilter();
    }

    handleChangeTab = (e, value) => {
        this.setState({
            filter: {
                SearchValue: '',
                DatejoinFrom: null,
                DatejoinTo: null,
                OrgChartID: '',
                DutyID: '',
                EmployeeID: '',
                ProjectID: '',
            }
        });
        this.setState({tabIndex: value}, () => this.loadListEmployee());
    };

    handleChangePage = page => {
        this.filterEmployees.skip = page * this.filterEmployees.limit;
        this.loadListEmployee(1);
    };

    handleChangePerPage = perPage => {
        this.filterEmployees.skip = 0;
        this.filterEmployees.limit = perPage;
        this.loadListEmployee(1);
    };

    componentDidMount = async () => {
        await this.loadPermission();
        if (!this.state.iPermissions.W09F2005) return;
        this.loadListEmployee();
    };

    componentWillUnmount = () => {
        this.setState({
            listEmployee: {rows: [], total: 0},
            tabIndex: 0,
        });
        this.filterEmployees = {
            SearchValue: "",
            DatejoinFrom: null,
            DatejoinTo: null,
            OrgChartID: "",
            DutyID: "",
            EmployeeID: "",
            ProjectID: "",
            skip: 0,
            limit: 10
        };
    };

    onView = e => {
        const {data} = e;
        Config.setStoreState(this, this.filterEmployees);
        browserHistory.push({
            pathname: Config.getRootPath() + "W09F2000",
            state: {EmployeeID: data.EmployeeID, FormID: "W09F2005"}
        });
    };

    renderFilters = () => {
        const {getCboDuty} = this.props;
        const {loading, filter, dataOrgChart, dataCboEmployees, dataCboProjects} = this.state;

        return (
            <Filter
                onTextChanged={this.onSearch}
                placeholder={Config.lang("Tim_kiem_ho_so_nhan_su")}
                onOpenLoaded={this.onLoadDataFilter}
                isUseDDCore={true}
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Typography type={'p3'} color={'secondary'} style={{ fontWeight: 'bold' }}>{Config.lang("Ngay_vao")}</Typography>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 8 }} >
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div className={"display_row align-center"}>
                                            <Label className={"mgr5"} style={{ fontWeight: 500 }}>{Config.lang("Tu")}</Label>
                                            <DatePicker
                                                actionIconAt={'start'}
                                                max={filter.DatejoinTo || moment().format('YYYY/MM/DD')}
                                                placeholder={'DD/MM/YYYY'}
                                                displayFormat={'DD/MM/YYYY'}
                                                value={filter.DatejoinFrom}
                                                viewType={'outlined'}
                                                onChange={e => this.handleFilterChange("dateForm", e)}
                                                style={{ margin: 0 }}
                                                clearAble
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div className={"display_row align-center"}>
                                            <Label className={"mgr5"} style={{ fontWeight: 500 }}>{Config.lang("Den")}</Label>
                                            <DatePicker
                                                actionIconAt={'start'}
                                                max={moment().format('YYYY/MM/DD')}
                                                min={filter.DatejoinFrom}
                                                placeholder={'DD/MM/YYYY'}
                                                displayFormat={'DD/MM/YYYY'}
                                                value={filter.DatejoinTo}
                                                viewType={'outlined'}
                                                onChange={e => this.handleFilterChange("dateTo", e)}
                                                style={{ margin: 0 }}
                                                clearAble
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 8 }} >
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Dropdown
                                            dataSource={dataOrgChart ?? []}
                                            valueExpr={"OrgChartID"}
                                            displayExpr={"OrgName"}
                                            label={Config.lang("Co_cau_to_chuc")}
                                            multiple
                                            onChange={e => this.handleFilterChange("orgChartID", e.value)}
                                            value={filter?.OrgChartID?.length > 0 ? filter?.OrgChartID?.split(',') : []}
                                            clearAble
                                            viewType={'outlined'}
                                            placeholder={Config.lang('Chon')}
                                            style={{ margin: 0 }}
                                        >
                                            <TreeView
                                                allowSearch
                                                dataSource={dataOrgChart ?? []}
                                                disabledRelevantValue
                                                displayExpr={"OrgName"}
                                                id={"OrgChartID"}
                                                multiple
                                                multipleValueMode="single"
                                                onChange={(e, data) => this.handleFilterChange("orgChartID", data)}
                                                parentID={"OrgChartParentID"}
                                                value={filter?.OrgChartID?.length > 0 ? filter?.OrgChartID?.split(',') : []}
                                                valueExpr={"OrgChartID"}
                                            />
                                        </Dropdown>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 8 }} >
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Dropdown
                                            allowSearch
                                            dataSource={getCboDuty}
                                            valueExpr={"ProDutyID"}
                                            displayExpr={"{ProDutyID} - {ProDutyName}"}
                                            renderSelectedItem={'ProDutyName'}
                                            label={Config.lang("Chuc_vu")}
                                            onChange={e => this.handleFilterChange("dutyID", e)}
                                            value={filter.DutyID}
                                            clearAble
                                            viewType={'outlined'}
                                            placeholder={Config.lang('Chon')}
                                            style={{ margin: 0 }}
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 8 }} >
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Dropdown
                                            allowSearch
                                            dataSource={dataCboEmployees.rows}
                                            total={dataCboEmployees.total}
                                            skip={this.filterCboEmployees.skip}
                                            limit={this.filterCboEmployees.limit}
                                            displayExpr={'{EmployeeID} - {EmployeeName}'}
                                            valueExpr={'EmployeeID'}
                                            value={filter.EmployeeID}
                                            loading={loading.cboEmployees}
                                            viewType={'outlined'}
                                            clearAble
                                            label={Config.lang('Nhan_vien')}
                                            placeholder={Config.lang('Chon')}
                                            renderSelectedItem={'EmployeeName'}
                                            onChange={(e) => this.handleFilterChange("employeeID", e)}
                                            onInput={(e) => {
                                                const value = e.target.value;
                                                if (this.timer) clearTimeout(this.timer);
                                                this.timer = setTimeout(() => {
                                                    this.filterCboEmployees.strSearch = value;
                                                    this.filterCboEmployees.skip = 0;
                                                    this.loadCboEmployees(true);
                                                }, 500);
                                                
                                            }}
                                            onLoadMore={(e) => {
                                                this.filterCboEmployees.skip = e.skip;
                                                this.filterCboEmployees.limit = e.limit;
                                                this.loadCboEmployees();
                                            }}
                                            style={{ margin: 0 }}
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 8 }} >
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Dropdown
                                            allowSearch
                                            dataSource={dataCboProjects.rows}
                                            total={dataCboEmployees.total}
                                            skip={this.filterCboProject.skip}
                                            limit={this.filterCboProject.limit}
                                            displayExpr={'{ProjectID} - {ProjectName}'}
                                            valueExpr={'ProjectID'}
                                            value={filter.ProjectID}
                                            loading={loading.cboProjects}
                                            viewType={'outlined'}
                                            clearAble
                                            label={Config.lang('Du_an')}
                                            placeholder={Config.lang('Chon')}
                                            renderSelectedItem={'ProjectName'}
                                            onChange={(e) => this.handleFilterChange("projectID", e)}
                                            onInput={(e) => {
                                                const value = e.target.value;
                                                if (this.timer) clearTimeout(this.timer);
                                                this.timer = setTimeout(() => {
                                                    this.filterCboProject.strSearch = value;
                                                    this.filterCboProject.skip = 0;
                                                    this.loadCboProjects(true);
                                                }, 500);
                                            }}
                                            onLoadMore={(e) => {
                                                this.filterCboProject.skip = e.skip;
                                                this.filterCboProject.limit = e.limit;
                                                this.loadCboProjects();
                                            }}
                                            style={{ margin: 0 }}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup className={"display_row align-center valign-middle"}>
                                <Button startIcon={'Search'} color={'primary'} viewType={'outlined'} onClick={this.onFilter}>{Config.lang("Tim_kiem")}</Button>
                            </FormGroup>
                        </>
                    );
                }}
            />
        );
    };

    renderItem = (e) => {
        const {data} = e.row;

        return (
            <div className={"display_row align-center"}>
                <UserImage data={data} allowHover={false} width={42} height={42}/>
                {data.EmployeeID}
            </div>
        );
    };

    onOrderChanged = (e) => {
        if (!e || !e.column) return false;
        this.filterEmployees.fieldSort = e.column.dataField;
        this.filterEmployees.typeSort = e.typeSort;
        this.loadListEmployee();
    };

    render() {
        const {iPermissions, tabIndex, listEmployee, loaded} = this.state;
        if (!iPermissions.W09F2005) return null;
        const {skip, limit} = this.filterEmployees;
        return (
            <>
                <W09F2005Tabs
                    onSearch={this.handleSearch}
                    removeSearchText={this.handleRemoveSearchText}
                    tabIndex={tabIndex}
                    onChangeTab={this.handleChangeTab}
                    iPermissions={iPermissions}
                />
                <div className={"hidden"}>{this.renderFilters()}</div>
                <GridContainer
                    totalItems={listEmployee.total}
                    itemPerPage={limit}
                    skipPerPage={skip}
                    dataSource={listEmployee.rows}
                    keyExpr={"EmployeeID"}
                    onChangePage={this.handleChangePage}
                    onChangePerPage={this.handleChangePerPage}
                    onDbCellClick={this.onView}
                    typePaging={"remote"}
                    selection={{
                        mode: "single",
                    }}
                    isOrderAPI={true}
                    height={Config.getHeightGrid() - 49}
                    typeShort={window.innerWidth < 768}
                    loading={loaded}
                    showBorders={false}
                    showRowLines={false}
                    showColumnLines={false}
                    hoverStateEnabled={true}
                    onOrderChanged={this.onOrderChanged}
                >
                    <Column
                        cellRender={this.renderItem}
                        style={{backgroundColor: "#b3bbc1"}}
                        caption={Config.lang("Ma_NV")}
                        dataField={"EmployeeID"}
                        alignment={"center"}
                        minWidth={"145"}
                    />
                    <Column
                        style={{backgroundColor: "#b3bbc1"}}
                        caption={Config.lang("Ten_nhan_vien")}
                        dataField={"EmployeeName"}
                        alignment={"left"}
                        width={"200"}
                    />
                    <Column
                        style={{backgroundColor: "#b3bbc1"}}
                        caption={Config.lang("Gioi_tinh")}
                        dataField={"SexName"}
                        alignment={"center"}
                        width={110}
                    />
                    <Column
                        style={{backgroundColor: "#b3bbc1"}}
                        caption={Config.lang("Chung_minh_nhan_dan")}
                        dataField={"NumIDCard"}
                        alignment={"left"}
                        minWidth={200}
                    />
                    <Column
                        style={{backgroundColor: "#b3bbc1"}}
                        caption={Config.lang("The_can_cuoc")}
                        dataField={"IDCard"}
                        alignment={"left"}
                        minWidth={150}
                    />
                    <Column
                        style={{backgroundColor: "#b3bbc1"}}
                        caption={Config.lang("So_dien_thoai")}
                        dataField={"Telephone"}
                        alignment={"center"}
                        minWidth={150}
                    />
                    <Column
                        style={{backgroundColor: "#b3bbc1"}}
                        caption={Config.lang("Co_cau_to_chuc")}
                        dataField={"OrgchartName"}
                        alignment={"left"}
                        minWidth={150}
                    />
                    <Column
                        style={{backgroundColor: "#b3bbc1"}}
                        caption={Config.lang("Chuc_vu")}
                        dataField={"DutyName"}
                        alignment={"left"}
                        minWidth={110}
                    />
                    <Column
                        style={{backgroundColor: "#b3bbc1"}}
                        caption={Config.lang("Du_an")}
                        dataField={"ProjectName"}
                        alignment={"left"}
                        minWidth={110}
                    />
                    <Column
                        style={{backgroundColor: "#b3bbc1"}}
                        caption={Config.lang("Ngay_vao")}
                        dataField={"DateJoined"}
                        alignment={"center"}
                        dataType={"date"}
                        format={"dd/MM/yyyy"}
                        minWidth={120}
                    />
                    <Column
                        style={{backgroundColor: "#b3bbc1"}}
                        caption={Config.lang("Ma_HDLD")}
                        dataField={"LaborContractCode"}
                        alignment={"left"}
                        minWidth={120}
                    />
                    <Column
                        style={{backgroundColor: "#b3bbc1"}}
                        caption={Config.lang("Loai_HDLD")}
                        dataField={"WorkFormName"}
                        alignment={"left"}
                        minWidth={120}
                    />
                    <Column
                        style={{backgroundColor: "#b3bbc1"}}
                        caption={Config.lang("Ngay_tham_gia_bao_hiem")}
                        dataField={"SocInsIssueDate"}
                        alignment={"center"}
                        dataType={"date"}
                        format={"dd/MM/yyyy"}
                        minWidth={210}
                    />
                    <Column
                        style={{backgroundColor: "#b3bbc1"}}
                        caption={Config.lang("So_bao_hiem")}
                        dataField={"SocInsBookNo"}
                        alignment={"left"}
                        minWidth={140}
                    />
                </GridContainer>
            </>
        );
    }
}

W09F2005.propTypes = {
    classes: PropTypes.any,
    generalActions: PropTypes.any,
    getCboDuty: PropTypes.any,
    getCboEmployees: PropTypes.any,
    getCboProjects: PropTypes.any,
    w09f2005Action: PropTypes.any
};


export default compose(connect(state => ({
    tab: state.W09F2005.statusTab,
    getCboDuty: state.general.getCboDuty,
    getCboEmployees: state.general.getCboEmployees,
    getCboProjects: state.general.getCboProjects
}), dispatch => ({
    w09f2005Action: bindActionCreators(w09f2005Action, dispatch),
    generalActions: bindActionCreators(generalActions, dispatch)
})), withStyles(styles))(W09F2005);
