import React                 from "react";
import Config                from '../../../../config';
import propType              from "prop-types"
import W09F2200                          from "../W09F2200/W09F2200";
import {Row, Col, ButtonIcon, TextInput, Typography} from "diginet-core-ui/components";

class W09F2000Personal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            showW09F2200Popup: false,
            rowData: {}
        };
    }

    loadGrid = async () => {
        const {mode, onLoad} = this.props;
        if (onLoad) {
            await onLoad(mode, "", (error, data) => {
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                }
                this.setState({data: data});
            });
        }
    };

    componentDidMount = async () => {
        await this.loadGrid();
    };

    onEdit = (caption,oldPropertyID,fieldName, propertyID) => {
        const { employeeID } = this.props;
        this.setState({
            rowData: {
                caption,
                oldPropertyID,
                fieldName,
                propertyID,
                employeeID
            }
        });
        this.setState({showW09F2200Popup: true});
    };

    onCloseModal = () => {
        this.setState({showW09F2200Popup: false});
    };

    render() {
        const {isHidden} = this.props;
        const {data, rowData, showW09F2200Popup} = this.state;

        return (
            <div style={{width: "100%"}}>
                {showW09F2200Popup && <W09F2200 open={showW09F2200Popup}
                                                onClose={() => this.onCloseModal()}
                                                mode={"edit"}
                                                rowData={rowData}/>}
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <TextInput
                                readOnly
                                label={Config.lang("Dien_thoai")}
                                value={Config.helpers.getObjectValue(data, 'Telephone', '')}
                                endIcon={
                                    <ButtonIcon
                                        name={"Edit"}
                                        size={"small"}
                                        viewType={"ghost"}
                                        className={isHidden}
                                        onClick={() => this.onEdit(Config.lang("Dien_thoai"),
                                            Config.helpers.getObjectValue(data, 'Telephone', ''),
                                            "", "TEL")}
                                    />}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <TextInput
                                readOnly
                                label={Config.lang("Di_dong")}
                                value={Config.helpers.getObjectValue(data, 'Mobile', '')}
                                endIcon={
                                    <ButtonIcon
                                        name={"Edit"}
                                        size={"small"}
                                        viewType={"ghost"}
                                        className={isHidden}
                                        onClick={() => this.onEdit(Config.lang("Di_dong"),
                                            Config.helpers.getObjectValue(data, 'Mobile', ''),
                                            "", "MOBILE")}
                                    />}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <TextInput
                                readOnly
                                label={Config.lang("Dien_thoai_noi_bo")}
                                value={Config.helpers.getObjectValue(data, 'CompanyTelephone', '')}
                                endIcon={
                                    <ButtonIcon
                                        name={"Edit"}
                                        size={"small"}
                                        viewType={"ghost"}
                                        className={isHidden}
                                        onClick={() =>this.onEdit(Config.lang("Dien_thoai_noi_bo"),
                                            Config.helpers.getObjectValue(data, 'CompanyTelephone', ''),
                                            "", "CompanyTEL")}
                                    />}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <TextInput
                                readOnly
                                label={Config.lang("Email")}
                                value={Config.helpers.getObjectValue(data, 'Email', '')}
                                endIcon={
                                    <ButtonIcon
                                        name={"Edit"}
                                        size={"small"}
                                        viewType={"ghost"}
                                        className={isHidden}
                                        onClick={() =>this.onEdit(Config.lang("Email"),
                                            Config.helpers.getObjectValue(data, 'Email', '')
                                            ,"", "EMAIL")}
                                    />}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <TextInput
                                readOnly
                                label={Config.lang("Dia_chi_lien_lac")}
                                value={Config.helpers.getObjectValue(data, 'ContactAddress', '')}
                                endIcon={
                                    <ButtonIcon
                                        name={"Edit"}
                                        size={"small"}
                                        viewType={"ghost"}
                                        className={isHidden}
                                        onClick={() => this.onEdit(Config.lang("Dia_chi_lien_lac"),
                                            Config.helpers.getObjectValue(data, 'ContactAddress', '')
                                            ,"", "CONADDR")}
                                    />}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <TextInput
                                readOnly
                                label={Config.lang("Dia_chi_thuong_tru")}
                                value={Config.helpers.getObjectValue(data, 'PermanentAddress', '')}
                                endIcon={
                                    <ButtonIcon
                                        name={"Edit"}
                                        size={"small"}
                                        viewType={"ghost"}
                                        className={isHidden}
                                        onClick={() => this.onEdit(Config.lang("Dia_chi_thuong_tru"),
                                            Config.helpers.getObjectValue(data, 'PermanentAddress', ''),
                                            "", "PERADDR")}
                                    />}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <TextInput
                                readOnly
                                label={Config.lang("Dia_chi_tam_tru")}
                                value={Config.helpers.getObjectValue(data, 'TemporaryAddress', '')}
                                endIcon={
                                    <ButtonIcon
                                        name={"Edit"}
                                        size={"small"}
                                        viewType={"ghost"}
                                        className={isHidden}
                                        onClick={() =>this.onEdit(Config.lang("Dia_chi_tam_tru"),
                                            Config.helpers.getObjectValue(data, 'TemporaryAddress', ''),
                                            "","PROADDR")}
                                    />}
                            />
                        </Col>

                        <Col xs={12} sm={12} md={6} lg={6}>
                            {/*Nguoi lien he so 1*/}
                                <Typography>{Config.lang("Nguoi_lien_he_so_1")}</Typography>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <TextInput
                                            readOnly
                                            label={Config.lang("Ho_va_ten")}
                                            value={Config.helpers.getObjectValue(data, 'EmContactName1', '')}
                                            endIcon={
                                                <ButtonIcon
                                                    name={"Edit"}
                                                    size={"small"}
                                                    viewType={"ghost"}
                                                    className={isHidden}
                                                    onClick={() =>this.onEdit(Config.lang("Ho_va_ten"),
                                                        Config.helpers.getObjectValue(data, 'EmContactName1', ''),
                                                        "", "EMCONNAME1")}
                                                />}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <TextInput
                                            readOnly
                                            label={Config.lang("Quan_he")}
                                            value={Config.helpers.getObjectValue(data, 'EmRelationName1', '')}
                                            endIcon={
                                                <ButtonIcon
                                                    name={"Edit"}
                                                    size={"small"}
                                                    viewType={"ghost"}
                                                    className={isHidden}
                                                    onClick={() => this.onEdit(Config.lang("Quan_he"),
                                                        Config.helpers.getObjectValue(data, 'EmRelationName1', ''),
                                                        "EmRelationID", "EMCONREL1")}
                                                />}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <TextInput
                                            readOnly
                                            label={Config.lang("Dien_thoai")}
                                            value={Config.helpers.getObjectValue(data, 'EmContactPhone1', '')}
                                            endIcon={
                                                <ButtonIcon
                                                    name={"Edit"}
                                                    size={"small"}
                                                    viewType={"ghost"}
                                                    className={isHidden}
                                                    onClick={() => this.onEdit(Config.lang("Dien_thoai"),
                                                        Config.helpers.getObjectValue(data, 'EmContactPhone1', ''),
                                                        "", "EMCONPHONE1")}
                                                />}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <TextInput
                                            readOnly
                                            label={Config.lang("Dia_chi")}
                                            value={Config.helpers.getObjectValue(data, 'EmContactAddress1', '')}
                                            endIcon={
                                                <ButtonIcon
                                                    name={"Edit"}
                                                    size={"small"}
                                                    viewType={"ghost"}
                                                    className={isHidden}
                                                    onClick={() =>this.onEdit(Config.lang("Dia_chi"),
                                                        Config.helpers.getObjectValue(data, 'EmContactAddress1', ''),
                                                        "", "EMCONADD1")}
                                                />}
                                        />
                                    </Col>
                                </Row>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            {/*Nguoi lien he so 2*/}
                                <Typography>{Config.lang("Nguoi_lien_he_so_2")}</Typography>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <TextInput
                                            readOnly
                                            label={Config.lang("Ho_va_ten")}
                                            value={Config.helpers.getObjectValue(data, 'EmContactName2', '')}
                                            endIcon={
                                                <ButtonIcon
                                                    name={"Edit"}
                                                    size={"small"}
                                                    viewType={"ghost"}
                                                    className={isHidden}
                                                    onClick={() => this.onEdit(Config.lang("Ho_va_ten"),
                                                        Config.helpers.getObjectValue(data, 'EmContactName2', ''),
                                                        "", "EMCONNAME2")}
                                                />}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <TextInput
                                            readOnly
                                            label={Config.lang("Quan_he")}
                                            value={Config.helpers.getObjectValue(data, 'EmRelationName2', '')}
                                            endIcon={
                                                <ButtonIcon
                                                    name={"Edit"}
                                                    size={"small"}
                                                    viewType={"ghost"}
                                                    className={isHidden}
                                                    onClick={() => this.onEdit(Config.lang("Quan_he"),
                                                        Config.helpers.getObjectValue(data, 'EmRelationName2', ''),
                                                        "EmRelationID", "EMCONREL2")}
                                                />}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <TextInput
                                            readOnly
                                            label={Config.lang("Dien_thoai")}
                                            value={Config.helpers.getObjectValue(data, 'EmContactPhone2', '')}
                                            endIcon={
                                                <ButtonIcon
                                                    name={"Edit"}
                                                    size={"small"}
                                                    viewType={"ghost"}
                                                    className={isHidden}
                                                    onClick={() =>this.onEdit(Config.lang("Dien_thoai"),
                                                        Config.helpers.getObjectValue(data, 'EmContactPhone2', ''),
                                                        "","EMCONPHONE2")}
                                                />}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <TextInput
                                            readOnly
                                            label={Config.lang("Dia_chi")}
                                            value={Config.helpers.getObjectValue(data, 'EmContactAddress2', '')}
                                            endIcon={
                                                <ButtonIcon
                                                    name={"Edit"}
                                                    size={"small"}
                                                    viewType={"ghost"}
                                                    className={isHidden}
                                                    onClick={() =>this.onEdit(Config.lang("Dia_chi"),
                                                        Config.helpers.getObjectValue(data, 'EmContactAddress2', ''),
                                                        "", "EMCONADD2")}
                                                />}
                                        />
                                    </Col>
                                </Row>
                        </Col>
                    </Row>
            </div>
        );
    }

}

W09F2000Personal.propType = {
    data : propType.any,
};

export default W09F2000Personal;
