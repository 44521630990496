import React from "react";
import Config from '../../../../config';
import GridContainer from "../../../grid-container/grid-container";
import {Column} from "devextreme-react/data-grid";
import propTypes from "prop-types"
import {MForm} from "../../../common/form-material";
import * as W09F2001Actions from '../../../../redux/W0X/W09F2001/W09F2001_actions';
import * as generalActions from "../../../../redux/general/general_actions";
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import {Row, Col, Dropdown, Button, Checkbox, Typography} from "diginet-core-ui/components";
import {makeStyles} from "diginet-core-ui/theme";
const useStyles = makeStyles(theme => ({
    dropdownReport: {
        width: 300,
        marginBottom: 0,
        [theme.breakpoints.down("sm")]: {
            width: 200,
        }
    },
}));
class W09F2000Contracts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataContracts: {
                rows: [],
                total: 0
            },
            dataSub: {
                rows: [],
                total: 0
            },
            cbReport: { rows: [], total: 0 },
            error:{}
        };
        this.filterMain = {
            skip: 0,
            limit: 5,
        };
        this.filterSub = {
            skip: 0,
            limit: 5,
        };

        this.cbReportFilter = {
            limit: 10,
            slip: 0
        };
    }

    loadGrid = async (type = "") => {
        const {mode, onLoad} = this.props;
        let {skip, limit} = this.filterMain;
        if (type === "SubContract") {
            skip = this.filterSub.skip;
            limit = this.filterSub.limit;
        }
        if (onLoad) {
            await onLoad(mode, type, (error, data) => {
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                }
                if (type === "SubContract") {
                    const rows = data?.rows?.length > 0 ? data?.rows?.map((row, idx) => ({...row, ID: idx})) : [];
                    this.setState({dataSub: {rows: rows, total: data && data.total ? data.total : 0 }});
                } else {
                    const rows = data?.rows?.length > 0 ? data?.rows?.map((row, idx) => ({...row, ID: idx})) : [];
                    this.setState({dataContracts: {rows: rows, total: data && data.total ? data.total : 0 }});
                }
            }, skip, limit, type === "Contract");
        }
    };

    componentDidMount = async () => {
        await this.loadGrid("Contract");
        this.loadGrid("SubContract");
        this.getCbReport();
    };

    handleChangePageMain = (page) => {
        this.filterMain.skip = page * this.filterMain.limit;
        this.loadGrid("Contract");
    };

    handleChangePerPageMain = (perPage) => {
        this.filterMain.skip = 0;
        this.filterMain.limit = perPage;
        this.loadGrid("Contract");
    };

    handleChangePageSub = (page) => {
        this.filterSub.skip = page * this.filterSub.limit;
        this.loadGrid("SubContract");
    };

    handleChangePerPageSub = (perPage) => {
        this.filterSub.skip = 0;
        this.filterSub.limit = perPage;
        this.loadGrid("SubContract");
    };

    renderItem = (e) => {
        if (!e) return false;
        const {data} = e;
        return (
            <div className={"display_row align-center"}>
                <span style={{
                    width: 20,
                    height: 20,
                    borderRadius: 20,
                    border: (data && Number(data.ValidContractStatus) === 1 ? "none" : `1px solid ${Config.coreTheme?.colors?.dark6}`),
                    backgroundColor: (data && Number(data.ValidContractStatus) === 1 ? Config.coreTheme?.colors?.info : "#fff")  }} className={'mgr5'}/>
                <span>{data.LaborContractNo || ""}</span>
            </div>
        );
    };

    getCbReport = (isLoadmore = false) => {
        const {cbReport} = this.state;
        const params = {
            limit: this.cbReportFilter.limit,
            skip: this.cbReportFilter.skip,
            TranTypeID: "W6_HDLD"
        };
        this.props.W09F2001Actions.getCbReport(params, (errors, data) => {
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
            const rows = data && data.rows ? data.rows : data;
            const total = data && data.total ? data.total : data.length;
            const dataT = {
                    rows: !isLoadmore ? rows : cbReport.rows.concat(rows),
                    total: total,
            };
            this.setState({cbReport: dataT});
        })

    };

    onExport = () => {
        const {ReportID, cbReport, dataContracts} = this.state;
        const validateRules = [
            {
                name: "ReportID",
                rules: "isRequired",
                value: ReportID
            }
        ];
        const valid = MForm.formValidation(validateRules);
        if (Object.keys(valid).length > 0) {
            this.setState({error:valid});
            return false;
        }

        const report = cbReport.rows.find(i=>i.ReportID === ReportID);

        const params = {
            ReportTypeID: report.ReportTypeID,
            ReportID: report.ReportID,
            TitleU: report.TitleU,
            Remark:report.Remark,
            URL:report.URL,
            FileExt:report.FileExt,
            ExportType:report.FileExt,
            data:JSON.stringify(dataContracts.rows)
        };

        this.props.generalActions.exportMulti(params, (err, data)=>{
            if (err) {
                Config.popup.show("ERROR", err);
                return false;
            }
            else if (data) {
                const link = document.createElement('a');
                link.href = data.URL ? data.URL : "";
                link.download = data.fileName ? data.fileName : "";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        })

    };

    render() {
        const {ReportID, cbReport, error, dataContracts, dataSub} = this.state;
        const classes = useStyles();
        return (
            <div style={{width: "100%"}}>
                <div className={"display_row pdb5x valign-bottom align-right"}>
                    <div className={"mgr2x"}>
                        <Dropdown
                            className={classes.dropdownReport}
                            label={!ReportID ? Config.lang('Mau_bao_cao') : ""}
                            required={!ReportID}
                            error={error && error['ReportID']}
                            dataSource={cbReport.rows}
                            total={cbReport.total}
                            skip={this.cbReportFilter.skip}
                            limit={this.cbReportFilter.limit}
                            displayExpr={"TitleU"}
                            valueExpr={"ReportID"}
                            value={ReportID}
                            viewType={"outlined"}
                            onChange={(e) => this.setState({ ReportID: e.value })}
                            onLoadMore={(e) => {
                                this.cbReportFilter.skip = e.skip;
                                this.getCbReport(true);
                            }}

                        />
                    </div>
                    <Button disabled={!ReportID}
                            label={"In"}
                            color={"primary"}
                            viewType={"filled"}
                            onClick={this.onExport}
                    />
                </div>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <GridContainer
                                keyExpr={"ID"}
                                dataSource={dataContracts && dataContracts.rows ? dataContracts.rows : []}
                                totalItems={dataContracts && dataContracts.total ? dataContracts.total : 0}
                                itemPerPage={this.filterMain.limit}
                                skipPerPage={this.filterMain.skip}
                                onChangePage={this.handleChangePageMain}
                                onChangePerPage={this.handleChangePerPageMain}
                                typePaging={"remote"}
                                pagerFullScreen={false}
                                loading={false}
                            >
                                <Column
                                    caption={Config.lang("So_HDLD")}
                                    dataField={"LaborContractNo"}
                                    cellRender={this.renderItem}
                                />
                                <Column
                                    caption={Config.lang("Loai_HDLD")}
                                    dataField={"ContractTypeName"}
                                />
                                <Column
                                    caption={Config.lang("Ngay_lap")}
                                    dataField={"PlaceDate"}
                                />
                                <Column
                                    caption={Config.lang("Thoi_han_hop_dong")}
                                    dataField={"ContractPeriod"}
                                />
                                <Column
                                    caption={Config.lang("Trang_thai_lam_viec")}
                                    dataField={"StatusName"}
                                />
                                <Column
                                    caption={Config.lang("Phu_luc")}
                                    dataField={"IsSubContract"}
                                    alignment={"center"}
                                    cellRender={(e) => {
                                        const {data} = e;
                                        return (
                                            <div className={"display_row valign-middle"}>
                                                <Checkbox
                                                    checked={!!data.IsSubContract}
                                                    color="primary"
                                                    size={"small"}
                                                    disabled={true}
                                                />
                                            </div>
                                        )
                                    }}
                                />
                            </GridContainer>
                        </Col>
                    </Row>
                <div className={"mgt4x"}>
                    {/*Phu luc*/}
                    <fieldset>
                        <Typography>{Config.lang("Phu_luc")}</Typography>
                            <GridContainer
                                keyExpr={"ID"}
                                dataSource={dataSub && dataSub.rows ? dataSub.rows : []}
                                totalItems={dataSub && dataSub.total ? dataSub.total : 0}
                                listPerPage={[5, 10, 15, 20]}
                                itemPerPage={this.filterSub.limit}
                                skipPerPage={this.filterSub.skip}
                                onChangePage={this.handleChangePageSub}
                                onChangePerPage={this.handleChangePerPageSub}
                                typePaging={"remote"}
                                pagerFullScreen={false}
                                loading={false}
                            >
                                <Column
                                    caption={Config.lang("So_phu_luc")}
                                    dataField={"SubContractSerial"}
                                />
                                <Column
                                    caption={Config.lang("So_HDLD")}
                                    dataField={"LaborContractNo"}
                                />
                                <Column
                                    caption={Config.lang("Loai_phu_luc")}
                                    dataField={"SubContractTypeName"}
                                />
                                <Column
                                    caption={Config.lang("Thoi_han_phu_luc")}
                                    dataField={"SubContractPeriod"}
                                />
                                <Column
                                    caption={Config.lang("Ngay_ky_phu_luc")}
                                    dataField={"SubContractDate"}
                                />
                            </GridContainer>
                    </fieldset>
                </div>
            </div>
        )
    }

}

W09F2000Contracts.propTypes = {
    data: propTypes.any,
};

export default compose(
    connect(
        () => ({}),
        (dispatch) => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            W09F2001Actions: bindActionCreators(W09F2001Actions, dispatch),
        }),
        null,
        { forwardRef: true }))
(W09F2000Contracts);

