import React from "react";
import Config         from "../../../../config/index";
import propType       from "prop-types";
import W09F2200                          from "../W09F2200/W09F2200";
import {Row, Col, TextInput, ButtonIcon, Avatar, Typography} from "diginet-core-ui/components";
import { makeStyles } from "diginet-core-ui/theme";
const useStyles = makeStyles(theme => ({
    avatar: {
        display: 'flex',
        alignItem: "center",
        justifyContent: "center",
        [theme.breakpoints.down('sm')]: {
            justifyContent:'center'
        },
    },
}));

class W09F2000Employees extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            showW09F2200Popup: false,
            rowData: {}
        };
    }

    loadGrid = async () => {
        const {mode, onLoad} = this.props;
        if (onLoad) {
            await onLoad(mode, "", (error, data) => {
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                }
                this.setState({data: data});
            });
        }
    };

    componentDidMount = async () => {
        await this.loadGrid();
    };

    onEdit = (caption, oldPropertyID, fieldName, propertyID) => {
        const { employeeID } = this.props;
        this.setState({
            rowData: {
                caption,
                oldPropertyID,
                fieldName,
                propertyID,
                employeeID
            }
        });
        this.setState({showW09F2200Popup: true});
    };

    onCloseModal = () => {
        this.setState({showW09F2200Popup: false});
    };

    render() {
        const classes = useStyles();
        const {isHidden} = this.props;
        const {data, rowData, showW09F2200Popup} = this.state;
        if (!data) return null;
        // format date
        let date = Config.helpers.getObjectValue(data, 'DateJoined', null);
        const value = new Date(date);
        date = data && new Intl.DateTimeFormat("vi",{
            day:"2-digit",
            month: "2-digit",
            year:"numeric"
        }).format(value);
        const user = Config.getUser({EmployeeID: data.EmployeeID || ""})

        return(
            <div style={{width: '100%'}}>
                {showW09F2200Popup && <W09F2200 open={showW09F2200Popup}
                                                onClose={() => this.onCloseModal()}
                                                mode={"edit"}
                                                rowData={rowData}/>}
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Row className={"display_row align-center flex-wrap"}>
                                <Col xs={12} sm={12} md={3} lg={3}>
                                    <div className={classes.avatar}>
                                        <Avatar src={Config.getCDNPath(user?.UserPictureURL)} width={136} height={136} hoverAble={false}/>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={4} lg={4}>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <TextInput
                                                    readOnly
                                                    label={Config.lang("Ma_nhan_vien")}
                                                    value={Config.helpers.getObjectValue(data, 'EmployeeID', '')}
                                                />
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <TextInput
                                                    readOnly
                                                    label={Config.lang("Gioi_tinh")}
                                                    value={Config.helpers.getObjectValue(data, 'SexName', '')}
                                                />
                                            </Col>
                                        </Row>
                                </Col>
                                <Col xs={12} sm={12} md={5} lg={5}>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <TextInput
                                                readOnly
                                                label={Config.lang("Ho_va_ten")}
                                                value={data?.EmployeeName ?? user?.EmployeeName ?? ""}
                                            />
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <TextInput
                                                readOnly
                                                label={Config.lang("Ngay_sinh")}
                                                value={
                                                    Config.convertDate(Config.helpers.getObjectValue(data, 'Birthdate', ''),
                                                        "", "DD/MM/YYYY", true)}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                        <Col xs={12} sm={12} md={6} lg={6}>
                            <TextInput
                                readOnly
                                label={Config.lang("Ngay_vao_lam")}
                                value={date}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <TextInput
                                readOnly
                                label={Config.lang("Trang_thai_lam_viec")}
                                value={Config.helpers.getObjectValue(data, 'StatusName', '')}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <TextInput
                                readOnly
                                label={Config.lang("Dan_toc")}
                                value={Config.helpers.getObjectValue(data, 'EthnicName', '')}
                                endIcon={
                                    <ButtonIcon
                                        name={"Edit"}
                                        size={"small"}
                                        viewType={"ghost"}
                                        className={isHidden}
                                        onClick={() => this.onEdit(Config.lang("Dan_toc"),
                                            Config.helpers.getObjectValue(data, 'EthnicName', ''),
                                            "EthnicID", "ETHNIC")}
                                    />}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <TextInput
                                readOnly
                                label={Config.lang("Ton_giao")}
                                value={Config.helpers.getObjectValue(data, 'ReligionName', '')}
                                endIcon={
                                    <ButtonIcon
                                        name={"Edit"}
                                        size={"small"}
                                        viewType={"ghost"}
                                        className={isHidden}
                                        onClick={() =>this.onEdit(Config.lang("Ton_giao"),
                                            Config.helpers.getObjectValue(data, 'ReligionName', ''),
                                            "ReligionID", "RELIGION")}
                                    />}
                            />
                        </Col>

                        <Col xs={12} sm={12} md={6} lg={6}>
                            <TextInput
                                readOnly
                                label={Config.lang("Quoc_tich")}
                                value={Config.helpers.getObjectValue(data, 'CountryName', '')}
                                endIcon={
                                    <ButtonIcon
                                        name={"Edit"}
                                        size={"small"}
                                        viewType={"ghost"}
                                        className={isHidden}
                                        onClick={() => this.onEdit(Config.lang("Quoc_tich"),
                                            Config.helpers.getObjectValue(data, 'CountryName', ''),
                                            "CountryID", "COUNTRY")}
                                    />}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <TextInput
                                readOnly
                                label={Config.lang("Que_quan")}
                                value={Config.helpers.getObjectValue(data, 'NativePlace', '')}
                                endIcon={
                                    <ButtonIcon
                                        name={"Edit"}
                                        size={"small"}
                                        viewType={"ghost"}
                                        className={isHidden}
                                        onClick={() =>this.onEdit(Config.lang("Que_quan"),
                                            Config.helpers.getObjectValue(data, 'NativePlace', ''),
                                            "IDCardPlace", "NATIVEPLACE")}
                                    />}
                            />
                        </Col>

                        <Col xs={12} sm={12} md={6} lg={6}>
                            <TextInput
                                readOnly
                                label={Config.lang("Tinh_trang_hon_nhan")}
                                value={Config.helpers.getObjectValue(data, 'MaritalStatusName', '')}
                                endIcon={
                                    <ButtonIcon
                                        name={"Edit"}
                                        size={"small"}
                                        viewType={"ghost"}
                                        className={isHidden}
                                        onClick={() =>this.onEdit(Config.lang("Tinh_trang_hon_nhan"),
                                            Config.helpers.getObjectValue(data, 'MaritalStatusName', ''),
                                            "MaritalStatus", "MARITALSTATUS")}
                                />}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <TextInput
                                readOnly
                                label={Config.lang("Ma_so_thue_ca_nhan")}
                                value={Config.helpers.getObjectValue(data, 'IncomeTaxCode', '')}
                            />
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={12}>
                            {/*Thong tin cong viec*/}
                                <Typography>{Config.lang("Thong_tin_cong_viec")}</Typography>
                                <Row>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <TextInput
                                                    readOnly
                                                    label={Config.lang("Co_cau_to_chuc")}
                                                    value={Config.helpers.getObjectValue(data, 'OrgChartName', '')}
                                                />
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <TextInput
                                                    readOnly
                                                    label={Config.lang("Du_an")}
                                                    value={Config.helpers.getObjectValue(data, 'ProjectName', '')}
                                                />
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <TextInput
                                                    readOnly
                                                    label={Config.lang("Chuc_vu")}
                                                    value={Config.helpers.getObjectValue(data, 'DutyName', '')}
                                                />
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <TextInput
                                                    readOnly
                                                    label={Config.lang("Nguoi_quan_ly_truc_tiep")}
                                                    value={Config.helpers.getObjectValue(data, 'DirectManagerName', '')}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <TextInput
                                                    readOnly
                                                    label={Config.lang("Tai_khoan_ngan_hang")}
                                                    value={Config.helpers.getObjectValue(data, 'BankAccountNo', '')}
                                                />
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <TextInput
                                                    readOnly
                                                    label={Config.lang("Ngan_hang")}
                                                    value={Config.helpers.getObjectValue(data, 'BankName', '')}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                        </Col>

                        <Col xs={12} sm={12} md={6} lg={6}>
                            {/*Chung minh nhan dan*/}
                                <Typography>{Config.lang("Chung_minh_nhan_dan")}</Typography>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <TextInput
                                            readOnly
                                            label={Config.lang("So")}
                                            value={Config.helpers.getObjectValue(data, 'NumIDCard', '')}
                                            endIcon={
                                                <ButtonIcon
                                                    name={"Edit"}
                                                    size={"small"}
                                                    viewType={"ghost"}
                                                    className={isHidden}
                                                    onClick={() =>this.onEdit(Config.lang("So_CMND"),
                                                        Config.helpers.getObjectValue(data, 'NumIDCard', ''),
                                                        "", "NUMIDCARD")}
                                                />}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <TextInput
                                            readOnly
                                            label={Config.lang("Ngay_cap")}
                                            value={
                                                Config.convertDate(Config.helpers.getObjectValue(data, 'NumIDCardDate', ''),
                                                    "", "DD/MM/YYYY", true)}
                                            endIcon={
                                                <ButtonIcon
                                                    name={"Edit"}
                                                    size={"small"}
                                                    viewType={"ghost"}
                                                    className={isHidden}
                                                    onClick={() => this.onEdit(Config.lang("Ngay_cap_cmnd"),
                                                        Config.convertDate(Config.helpers.getObjectValue(data, 'NumIDCardDate', ''),
                                                            "", "DD/MM/YYYY", true),"", "NUMIDDATE")}
                                                />}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <TextInput
                                            readOnly
                                            label={Config.lang("Noi_cap")}
                                            value={Config.helpers.getObjectValue(data, 'NumIDCardPlace', '')}
                                            endIcon={
                                                <ButtonIcon
                                                    name={"Edit"}
                                                    size={"small"}
                                                    viewType={"ghost"}
                                                    className={isHidden}
                                                    onClick={() => this.onEdit(Config.lang("Noi_cap_cmnd"),
                                                        Config.helpers.getObjectValue(data, 'NumIDCardPlace', ''),
                                                        "IDCardPlace", "NUMIDPLACE")}
                                                />}
                                        />
                                    </Col>
                                </Row>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            {/*The can cuoc*/}
                                <Typography>{Config.lang("The_can_cuoc")}</Typography>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <TextInput
                                            readOnly
                                            label={Config.lang("So")}
                                            value={Config.helpers.getObjectValue(data, 'IDCard', '')}
                                            endIcon={
                                                <ButtonIcon
                                                    name={"Edit"}
                                                    size={"small"}
                                                    viewType={"ghost"}
                                                    className={isHidden}
                                                    onClick={() => this.onEdit(Config.lang("So_can_cuoc"),
                                                        Config.helpers.getObjectValue(data, 'IDCard', ''),
                                                        "", "IDCARD")}
                                                />}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <TextInput
                                            readOnly
                                            label={Config.lang("Ngay_cap")}
                                            value={Config.convertDate(Config.helpers.getObjectValue(data, 'IDCardDate', ''),
                                                "", "DD/MM/YYYY", true)}
                                            endIcon={
                                                <ButtonIcon
                                                    name={"Edit"}
                                                    size={"small"}
                                                    viewType={"ghost"}
                                                    className={isHidden}
                                                    onClick={() =>this.onEdit(Config.lang("Ngay_cap_the_can_cuoc"),
                                                        Config.convertDate(Config.helpers.getObjectValue(data, 'IDCardDate', ''), "", "DD/MM/YYYY", true)
                                                        ,"", "IDCARDDATE")}
                                                />}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <TextInput
                                            readOnly
                                            label={Config.lang("Noi_cap")}
                                            value={Config.helpers.getObjectValue(data, 'IDCardPlace', '')}
                                            endIcon={
                                                <ButtonIcon
                                                    name={"Edit"}
                                                    size={"small"}
                                                    viewType={"ghost"}
                                                    className={isHidden}
                                                    onClick={() =>this.onEdit(Config.lang("Noi_cap_the_can_cuoc"),
                                                        Config.helpers.getObjectValue(data, 'IDCardPlace', ''),
                                                        "IDCardPlace", "IDCARDPLACE")}
                                                />}
                                        />
                                    </Col>
                                </Row>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            {/*Ho chieu*/}
                                <Typography>{Config.lang("Ho_chieu")}</Typography>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <TextInput
                                            readOnly
                                            label={Config.lang("So")}
                                            value={Config.helpers.getObjectValue(data, 'PassportNo', '')}
                                            endIcon={
                                                <ButtonIcon
                                                    name={"Edit"}
                                                    size={"small"}
                                                    viewType={"ghost"}
                                                    className={isHidden}
                                                    onClick={() =>this.onEdit(Config.lang("So_ho_chieu"),
                                                        Config.helpers.getObjectValue(data, 'PassportNo', ''),
                                                        "","PASSPORT")}
                                                />}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <TextInput
                                            readOnly
                                            label={Config.lang("Ngay_cap")}
                                            value={Config.convertDate(Config.helpers.getObjectValue(data, 'PPDateFrom', ''),
                                                "", "DD/MM/YYYY", true)}
                                            endIcon={
                                                <ButtonIcon
                                                    name={"Edit"}
                                                    size={"small"}
                                                    viewType={"ghost"}
                                                    className={isHidden}
                                                    onClick={() =>this.onEdit(Config.lang("Ngay_cap_ho_chieu"),
                                                        Config.convertDate(Config.helpers.getObjectValue(data, 'PPDateFrom', ''),
                                                            "", "DD/MM/YYYY", true), "","PASSPORTBEGIN")}
                                                />}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <TextInput
                                            readOnly
                                            label={Config.lang("Noi_cap")}
                                            value={Config.helpers.getObjectValue(data, 'PassportPlace', '')}
                                            endIcon={
                                                <ButtonIcon
                                                    name={"Edit"}
                                                    size={"small"}
                                                    viewType={"ghost"}
                                                    className={isHidden}
                                                    onClick={() => this.onEdit(Config.lang("Noi_cap_ho_chieu"),
                                                        Config.helpers.getObjectValue(data, 'PassportPlace', ''),
                                                        "CountryID","PASSPORTPLACE")}
                                                    />}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <TextInput
                                            readOnly
                                            label={Config.lang("Ngay_het_han")}
                                            value={Config.convertDate(Config.helpers.getObjectValue(data, 'PPDateTo', ''), "", "DD/MM/YYYY", true)}
                                            endIcon={
                                                <ButtonIcon
                                                    name={"Edit"}
                                                    size={"small"}
                                                    viewType={"ghost"}
                                                    className={isHidden}
                                                    onClick={() => this.onEdit(Config.lang("Ngay_het_han_ho_chieu"),
                                                        Config.convertDate(Config.helpers.getObjectValue(data, 'PPDateTo', ''), "", "DD/MM/YYYY", true),
                                                        "","PASSPORTEND")}
                                                />}
                                        />
                                    </Col>
                                </Row>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            {/*Hoc van - Chuyen mon*/}
                                <Typography>{Config.lang("Hoc_van_chuyen_mon")}</Typography>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <TextInput
                                            readOnly
                                            label={Config.lang("Trinh_do_dao_tao")}
                                            value={Config.helpers.getObjectValue(data, 'EducationLevelName', '')}
                                            endIcon={
                                                <ButtonIcon
                                                    name={"Edit"}
                                                    size={"small"}
                                                    viewType={"ghost"}
                                                    className={isHidden}
                                                    onClick={() => this.onEdit(Config.lang("Trinh_do_dao_tao"),
                                                        Config.helpers.getObjectValue(data, 'EducationLevelName', ''),
                                                        "EducationLevelID","EDUCATION")}
                                                />}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <TextInput
                                            readOnly
                                            label={Config.lang("Trinh_do_chuyen_mon")}
                                            value={Config.helpers.getObjectValue(data, 'ProfessionalLevelName', '')}
                                            endIcon={
                                                <ButtonIcon
                                                    name={"Edit"}
                                                    size={"small"}
                                                    viewType={"ghost"}
                                                    className={isHidden}
                                                    onClick={() => this.onEdit(Config.lang("Trinh_do_chuyen_mon"),
                                                        Config.helpers.getObjectValue(data, 'ProfessionalLevelName', ''),
                                                        "ProfessionalLevelID", "PROFESSIONAL")}
                                                />}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <TextInput
                                            readOnly
                                            label={Config.lang("Chuyen_nganh")}
                                            value={Config.helpers.getObjectValue(data, 'MajorName', '')}
                                            endIcon={
                                                <ButtonIcon
                                                    name={"Edit"}
                                                    size={"small"}
                                                    viewType={"ghost"}
                                                    className={isHidden}
                                                    onClick={() => this.onEdit(Config.lang("Chuyen_nganh"),
                                                        Config.helpers.getObjectValue(data, 'MajorName', ''),
                                                        "MajorID", "MAJOR")}
                                                />}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <TextInput
                                            readOnly
                                            label={Config.lang("Trinh_do_chinh_tri")}
                                            value={Config.helpers.getObjectValue(data, 'PoliticsName', '')}
                                            endIcon={
                                                <ButtonIcon
                                                    name={"Edit"}
                                                    size={"small"}
                                                    viewType={"ghost"}
                                                    className={isHidden}
                                                    onClick={() => this.onEdit(Config.lang("Trinh_do_chinh_tri"),
                                                        Config.helpers.getObjectValue(data, 'PoliticsName', ''),
                                                        "PoliticsID", "POLITICS")}
                                                />}
                                        />
                                    </Col>
                                </Row>
                        </Col>
                    </Row>
            </div>
        )
    }
}

W09F2000Employees.propType = {
    data: propType.any
};

export default W09F2000Employees;
