import React         from "react";
import Config        from '../../../../config';
import GridContainer from "../../../grid-container/grid-container";
import {Column}      from "devextreme-react/data-grid";
import propTypes     from "prop-types"
import GridActionBar from "../../../grid-container/grid-actionbar";
import W09F2210      from "../W09F2210/W09F2210";
import moment        from "moment";
import { Row, Col, ButtonIcon } from "diginet-core-ui/components";

class W09F2000Family extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataFamily: {rows: [], total: 0},
            rowData: {},
            showW09F2210Popup: false
        };
        this.filter = {
            skip: 0,
            limit: 5,
        };
    }

    loadGrid = async () => {
        const {mode, onLoad} = this.props;
        const {skip, limit} = this.filter;
        if (onLoad) {
            await onLoad(mode, "", (error, data) => {
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                }
                const rows = data?.rows?.length > 0 ? data.rows.map((d, idx) => ({...d, ID: idx})) : [];
                this.setState({dataFamily: {rows: rows, total: data?.total || 0}});
            }, skip, limit);
        }
    };

    componentDidMount = async () => {
        await this.loadGrid();
    };

    handleChangePage = async (page) => {
        this.filter.skip = page * this.filter.limit;
        await this.loadGrid();
    };

    handleChangePerPage = async (perPage) => {
        this.filter.skip = 0;
        this.filter.limit = perPage;
        await this.loadGrid();
    };


    onCloseModal = () => {
        this.setState({showW09F2210Popup: false})
    };

    onEdit = (e) => {
        const {data} = e.row;
        const {employeeID} = this.props;
        const date = data?.BirthDate && moment(data?.BirthDate).format("DD/MM/YYYY");
        let oldPropertyID = (data?.RelativeName || "") + " - " + (data?.RelationName || "") +
                            " - " + (data?.SexName || "") + " - " + (date || "") + " - " + (data?.Telephone || "");
        this.setState({rowData: {
                mode: "edit",
                propertyID: "RELATIVE",
                oldPropertyID: oldPropertyID,
                RelativeID: data.RelativeID,
                IGE: data.IGE,
                employeeID
            },showW09F2210Popup: true});
    };
    cellRenderAction = (e) => {
        if(!e) return null;
        return (
            <GridActionBar>
                <div className="">
                    <ButtonIcon
                        circular
                        viewBox
                        viewType={"text"}
                        name={"edit"}
                        onClick={() => this.onEdit(e)}
                    />
                </div>
            </GridActionBar>
        );
    };

    render() {
        const {dataFamily, rowData, showW09F2210Popup} = this.state;
        return (
            <div>
                <div>
                    <div>
                        {showW09F2210Popup && <W09F2210 open={showW09F2210Popup}
                                                        onClose={() => this.onCloseModal()}
                                                        mode={"edit"}
                                                        rowData={rowData}/>}
                    </div>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <GridContainer
                                keyExpr={"ID"}
                                dataSource={dataFamily && dataFamily.rows ? dataFamily.rows : []}
                                totalItems={dataFamily && dataFamily.total ? dataFamily.total : 0}
                                itemPerPage={this.filter.limit}
                                skipPerPage={this.filter.skip}
                                onChangePage={this.handleChangePage}
                                onChangePerPage={this.handleChangePerPage}
                                pagerFullScreen={false}
                                hoverStateEnabled={true}
                                typePaging={"remote"}
                                loading={false}
                            >
                                <Column
                                    caption={Config.lang("Ten_nguoi_quan_he")}
                                    dataField={"RelativeName"}
                                />
                                <Column
                                    caption={Config.lang("Gioi_tinh")}
                                    dataField={"SexName"}
                                />
                                <Column
                                    caption={Config.lang("Ngay_sinh")}
                                    dataType={"date"}
                                    dataField={"BirthDate"}
                                    format={"dd/MM/yyyy"}
                                />
                                <Column
                                    caption={Config.lang("Quan_he")}
                                    dataField={"RelationName"}
                                />
                                <Column
                                    caption={Config.lang("So_dien_thoai")}
                                    dataField={"Telephone"}
                                />
                                <Column
                                    alignment={"center"}
                                    fixed={true}
                                    fixedPosition={"right"}
                                    allowEditing={false}
                                    cellRender={this.cellRenderAction}
                                />
                            </GridContainer>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }

}

W09F2000Family.propType = {
    data: propTypes.any,
};

export default W09F2000Family;
