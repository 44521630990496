/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 11/30/2022
 * @Example
 */

import React, { useEffect, useRef, useState } from "react";
import Config from "../../../../config";
import GridContainer from "../../../grid-container/grid-container";
import { Column } from "devextreme-react/data-grid";
import * as W09f2000Actions from "../../../../redux/W0X/W09F2000/W09F2000_actions";
import { useDispatch } from "react-redux";
import { Divider, TabContainer, TabHeader, TabItem } from "diginet-core-ui/components";
import PropTypes from "prop-types";

const tabs = [
    {
        ID: 0,
        mode: 121,
        permission: "W09F2000_BS",
        name: "Muc_luong",
    },
    {
        ID: 1,
        mode: 122,
        permission: "W09F2000_BC",
        name: "He_so",
    },
    {
        ID: 2,
        mode: 123,
        permission: "W09F2000_BL",
        name: "Ngach_bac_luong",
    },
];

const W09F2000SalaryInfo = props => {
    const { EmployeeID, permissions, mode, isInProfile } = props;
    const [tabIndex, setTabIndex] = useState(() => {
        const tab = tabs.find(d => d.mode === mode);
        return tab?.ID || 0;
    });
    const [dataGrid, setDataGrid] = useState({
        grid0: [],
        grid1: [],
        grid2: [],
    });
    const [loading, setLoading] = useState(false);
    const modeID = useRef(mode || 121);
    const dispatch = useDispatch();
    const columns = useRef({
        grid0: [
            { id: 0, dataField: "BaseSalaryName", caption: Config.lang("Muc_luong")},
            {
                id: 1,
                dataField: "BaseSalaryValues",
                caption: Config.lang("Gia_tri"),
                alignment: "right",
                dataType: "number",
                format: "#,##0.########"
            },
            { id: 2, dataField: "NumMonthBase", caption: Config.lang("So_thang_giu") },
            {
                id: 3,
                dataField: "BaseSalaryValid",
                caption: Config.lang("Ngay_hieu_luc"),
                alignment: "center",
                dataType: "date",
                format: "dd/MM/yyyy",
            },
            {
                id: 4,
                dataField: "NextBaseSalaryValid",
                caption: Config.lang("Ngay_xet_tiep_theo"),
                alignment: "center",
                dataType: "date",
                format: "dd/MM/yyyy",
            },
            {
                id: 5,
                dataField: "NextBaseSalaryValues",
                caption: Config.lang("Muc_luong_tiep_theo"),
                alignment: "center",
                dataType: "number",
                format: "#,##0.########"
            },
            { id: 6, dataField: "BaseCurrencyName", caption: Config.lang("Nguyen_te"), alignment: "center" },
        ],
        grid1: [
            { id: 0, dataField: "SalCoeName", caption: Config.lang("He_so_luong")},
            {
                id: 1,
                dataField: "SalCoeValues",
                caption: Config.lang("Gia_tri"),
                alignment: "right",
                dataType: "number",
                format: "#,##0.########"
            },
            { id: 2, dataField: "NumMonthSalCoe", caption: Config.lang("So_thang_giu"), alignment: "right" },
            {
                id: 3,
                dataField: "SalCoeValid",
                caption: Config.lang("Ngay_hieu_luc"),
                alignment: "center",
                dataType: "date",
                format: "dd/MM/yyyy",
            },
            {
                id: 4,
                dataField: "NextSalCoeValid",
                caption: Config.lang("Ngay_xet_tiep_theo"),
                alignment: "center",
                dataType: "date",
                format: "dd/MM/yyyy",
            },
            {
                id: 5,
                dataField: "NextSalCoeValues",
                caption: Config.lang("He_so_luong_tiep_theo"),
                alignment: "center",
                dataType: "number",
                format: "#,##0.########"
            },
            { id: 6, dataField: "SalCoeCurrencyID", caption: Config.lang("Nguyen_te"), alignment: "center" },
        ],
        grid2: [
            { id: 0, dataField: "SalaryName", caption: Config.lang("Thong_so_luong"), alignment: "left" },
            {   id: 1,
                dataField: "ValuesSal",
                caption: Config.lang("Gia_tri"),
                dataType: "number",
                format: "#,##0.########",
                alignment: "right"
            },
            {
                id: 2,
                dataField: "DateEnd",
                caption: Config.lang("Ngay_hieu_luc"),
                alignment: "center",
                dataType: "date",
                format: "dd/MM/yyyy",
            },
            {
                id: 3,
                dataField: "DateNext",
                caption: Config.lang("Ngay_xet_tiep_theo"),
                alignment: "center",
                dataType: "date",
                format: "dd/MM/yyyy",
            },
            { id: 4, dataField: "NetxSal", caption: Config.lang("Ngach_bac_tiep_theo_01"), alignment: "center" },
        ],
    });

    useEffect(() => {
        setLoading(false);
        loadGrid();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabIndex]);

    const loadGrid = () => {
        let params = {
            FormID: "W09F2000",
            EmployeeID: EmployeeID,
            Language: Config.language || "84",
            Mode: modeID.current,
            Type: "",
            skip: 0,
            limit: 10,
        };
        setLoading(true);
        dispatch(
            W09f2000Actions.getInfo(params, (errors, data) => {
                setLoading(false);
                if (errors) {
                    Config.popup.show("ERROR", errors);
                    return false;
                }
                const tabInfo = tabs.find(tab => tabIndex === tab.ID);
                const tabPermission = permissions[tabInfo?.permission ?? ""];
                // nếu Permission của tab > 0 hoặc isInProfile = true thì mới xét data lưới
                if(tabPermission !== 0 || isInProfile) {
                    const dt = data?.length > 0 ? data?.map((d, idx) => ({ ...d, ID: idx })) : [];
                    setDataGrid({[`grid${tabIndex}`]: dt});
                }

            })
        );
    };

    const handleTabChange = (id, mode) => {
        setLoading(true);
        setTabIndex(id);
        modeID.current = mode;
    };

    return (
        <>
            <TabContainer tabIndex={tabIndex}>
                <TabHeader>
                    {tabs &&
                        tabs.map(tab => {
                            return (
                                <TabItem
                                    key={tab.ID}
                                    index={tab.ID}
                                    onClick={() => handleTabChange(tab.ID, tab.mode)}
                                    label={Config.lang(tab.name)}
                                />
                            );
                        })}
                </TabHeader>
            </TabContainer>
            <Divider className={"mgb2x"} />
            <GridContainer
                dataSource={dataGrid[`grid${tabIndex}`]}
                loading={loading}
                keyExpr={"ID"}
                typePaging={"remote"}
                showRowLines={false}
                pagerFullScreen={false}
                height={300}
                grouping={{
                    allowCollapsing: true,
                    expandMode: "rowClick",
                }}
            >
                {tabIndex === 2 && (
                    <Column
                        dataField={"GrpSalaryName"}
                        sortOrder={"asc"}
                        groupIndex={0}
                        groupCellTemplate={(cellElement, cellInfo) => {
                            const { data } = cellInfo;
                            cellElement.innerHTML = `<span style="color: ${
                                Config.coreTheme.colors.info
                            }; font-size: 12px;font-weight: 700; line-height: 16px; text-transform: uppercase">${
                                data?.items?.[0]?.GrpSalaryName || data?.collapsedItems?.[0]?.GrpSalaryName || ""
                            }</span>`;
                        }}
                    />
                )}
                {tabIndex === 2 && <Column dataField={""} width={200} />}
                {columns.current[`grid${tabIndex}`].map(column => {
                    return <Column key={column.id} {...column} />;
                })}
            </GridContainer>
        </>
    );
};

W09F2000SalaryInfo.propTypes = {
    mode: PropTypes.number,
    EmployeeID: PropTypes.string,
    permissions: PropTypes.object,
};

W09F2000SalaryInfo.defaultProps = {
    mode: 121,
    EmployeeID: "",
    permissions: {},
};

export default W09F2000SalaryInfo;
