import React from "react";
import Config from '../../../../config';
import GridContainer from "../../../grid-container/grid-container";
import {Column} from "devextreme-react/data-grid";
import propTypes from "prop-types"
import {Row, Col, Tooltip, Icon, Typography} from "diginet-core-ui/components";
import { makeStyles } from "diginet-core-ui/theme";
import * as W09f2000Actions from "../../../../redux/W0X/W09F2000/W09F2000_actions";
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
const useStyle = makeStyles((theme) => ({
    iconLeave: {
        width: theme.spacing(14),
        height: theme.spacing(14),
        cursor: "pointer",
        borderRadius: "50%",
        position: "relative"
    },
    iconInfo: {
        right: theme.spacing(-2),
        bottom: theme.spacing(-2),
        position: 'absolute',
    },
    danger: {
        backgroundColor: theme.colors.semantic.danger
    },
    success: {
        backgroundColor: theme.colors.semantic.success
    },
    info: {
        backgroundColor: theme.colors.semantic.info
    },
}));

class W09F2000Leave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            leaveAmount: null,
            dataLeaveInfo: {
                rows: [],
                total: 0
            }
        };
        this.filter = {
            skip: 0,
            limit: 10,
        };
    }

    loadGrid = async (type = "") => {
        const {mode, onLoad} = this.props;
        const {skip, limit} = this.filter;
        if (onLoad) {
            await onLoad(mode, type, (error, data) => {
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                }
                const rows = data?.rows?.length > 0 ? data.rows.map((d, idx) => ({...d, ID: idx})) : [];
                this.setState({dataLeaveInfo: {
                    rows: rows,
                    total: data && data.total ? data.total : 0
                }});

            }, skip, limit, type === "LeaveInfo");
        }
    };

    loadLeaveAmount = () => {
        const {employeeID} = this.props;
        const params = {
            LeaveCycle : Config.getHRTransYear(),
            EmployeeID: employeeID,
            FormID: "W09F2000",
        };
        this.props.W09f2000Actions.getLeaveAmount(params, (errors, data) => {
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
            this.setState({leaveAmount: data?.[0] || {}});
        })
    }

    componentDidMount = async () => {
        this.loadLeaveAmount();
        await this.loadGrid("LeaveInfo");
    };

    handleChangePage = (page) => {
        this.filter.skip = page * this.filter.limit;
        this.loadGrid("LeaveInfo");
    };

    handleChangePerPage = (perPage) => {
        this.filter.skip = 0;
        this.filter.limit = perPage;
        this.loadGrid("LeaveInfo");
    };

    renderTooltip = (num1 = 0, num2 = 0, num3 = 0, num4 = 0) => {
        return (
            <div>
                <Typography color={"system/white"}>{Config.lang("Phep_ton")} : {num1}</Typography>
                <Typography color={"system/white"}>{Config.lang("Phep_tham_nien")} : {num2}</Typography>
                <Typography color={"system/white"}>{Config.lang("Phep_nam")} : {num3}</Typography>
                <Typography color={"system/white"}>{Config.lang("Phep_bu")} : {num4}</Typography>
            </div>
        );
    };

    render() {
        const classes = useStyle();
        const {leaveAmount, dataLeaveInfo} = this.state;
        const leaves = [
            {
                ID: "TotalLeave",
                name: Config.lang("Tong_phep"),
                value: leaveAmount?.TotalLeave || 0,
                color: "info",
                tooltip: this.renderTooltip(leaveAmount?.BacklogLeave || 0,
                    leaveAmount?.SeniorityLeave || 0,
                    leaveAmount?.AnnualLeave || 0,
                    leaveAmount?.CompensationLeave || 0),
            },
            {
                ID: "UsedTotalLeave",
                name: `${Config.lang("Phep_da_nghi")}/ ${Config.lang("Het_han").toLowerCase()}`,
                value: leaveAmount?.UsedTotalLeave || 0,
                color: "danger",
                tooltip: this.renderTooltip(leaveAmount?.UsedBackLog || 0,
                    leaveAmount?.UsedSeniority || 0,
                    leaveAmount?.UsedAnnual || 0,
                    leaveAmount?.UsedCompensation || 0,
                ),
            },
            {
                ID: "TotalRemainLeave",
                name: Config.lang("Phep_con_lai"),
                value: leaveAmount?.TotalRemainLeave || 0,
                color: "success",
                tooltip: this.renderTooltip(leaveAmount?.RemainBackLog || 0,
                    leaveAmount?.RemainSeniority || 0,
                    leaveAmount?.RemainAnnual || 0,
                    leaveAmount?.RemainCompensation || 0,
                ),
            },
        ];

        return (
            <div>
                    <div className={`display_row align-center flex-wrap mgb4x valign-around`}>
                        {leaves && leaves.map(l => {
                            return (
                                <Tooltip key={l.ID} arrow={true} textAlign={"left"} title={l.tooltip}>
                                    <div className={"display_col align-center valign-middle"}>
                                        <div className={classes.iconLeave + " " + classes[l.color] + " display_row align-center valign-middle"}>
                                            <Typography type={"h3"} color={"system/white"}> {l.value || 0} </Typography>
                                            <Icon name={"info"} color={"system/active"} className={classes.iconInfo}/>
                                        </div>
                                        <Typography className={"mgt2x"}> {l.name || ""}</Typography>
                                    </div>
                                </Tooltip>
                            );
                        })}
                    </div>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <GridContainer
                                keyExpr={"ID"}
                                dataSource={dataLeaveInfo && dataLeaveInfo.rows ? dataLeaveInfo.rows : []}
                                totalItems={dataLeaveInfo && dataLeaveInfo.total ? dataLeaveInfo.total : 0}
                                itemPerPage={this.filter.limit}
                                skipPerPage={this.filter.skip}
                                onChangePage={this.handleChangePage}
                                onChangePerPage={this.handleChangePerPage}
                                typePaging={"remote"}
                                pagerFullScreen={false}
                                loading={false}
                            >
                                <Column
                                    caption={Config.lang("Ngay_da_nghi_phep")}
                                    dataType={"date"}
                                    format={"dd/MM/yyyy"}
                                    dataField={"LeaveDate"}
                                />
                                <Column
                                    caption={Config.lang("Loai_phep")}
                                    dataField={"LeaveTypeName"}
                                />
                                <Column
                                    caption={Config.lang("So_luong")}
                                    dataField={"Quantity"}
                                />
                                <Column
                                    caption={Config.lang("Ly_do")}
                                    dataField={"Reason"}
                                />
                            </GridContainer>
                        </Col>
                    </Row>
            </div>
        );
    }

}

W09F2000Leave.propTypes = {
    data: propTypes.any,
};

export default compose(connect(null, dispatch => ({
    W09f2000Actions: bindActionCreators(W09f2000Actions, dispatch),
}), null, {forwardRef: true}))(W09F2000Leave);