import React from "react";
import {bindActionCreators, compose} from "redux";
import Config from "../../../../config";
// import TabItems from "./W09F2000LabelItems";
import W09F2000Employees from "./W09F2000Employees";
import W09F2000Personal from "./W09F2000Personal";
import {connect} from "react-redux";
import * as W09f2000Actions from "../../../../redux/W0X/W09F2000/W09F2000_actions";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import {browserHistory} from "react-router";
import * as generalActions from "../../../../redux/general/general_actions";
import W09F2000Family from "./W09F2000Family";
import W09F2000Contracts from "./W09F2000Contracts";
import W09F2000Leave from "./W09F2000Leave";
import W09F2000Attachments from "./W09F2000Attachments";
import W09F2001HR from '../W09F2001/W09F2001HR';
import W09F2000Insurrance from "./W09F2000Insurrance";
import W09F2000HistorySocialInsurance from "./W09F2000HistorySocialInsurance";
import W09F2000Evaluation from "./W09F2000Evaluation";
import W09F2000TrainingHistory from "./W09F2000TrainingHistory";
import {
    Dropdown,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    Icon,
    TabHeader, TabItem, TabContainer, Row, Col, AccordionGroup
} from "diginet-core-ui/components";
import withCustomHook from "./W09F2000CustomHook";
import { makeStyles } from "diginet-core-ui/theme";
import { Loading } from "../../../common/loading/loading";
import W09F2000SalaryInfo from "./W09F2000SalaryInfo";
import W09F2210 from "../W09F2210/W09F2210";
const useStyles = makeStyles(theme => ({
    toolbar: {
        [theme.breakpoints.down('sm')]: {
            width: "100% !important",
            left: 1,
            '& > .DGN-UI-Typography': {
                fontSize: theme.spacing(4)
            }
        },
        width: "calc(100% - 100px) !important"
    },
    tabContent : {
        height: "calc(100vh - 100px)",
        overflow: "auto",
    },
    tabItem: {
        [theme.breakpoints.down('sm')]: {
            '& > .DGN-UI-Typography': {
                display: "none"
            }
        },
    },
    dropDown: {
        padding: theme.spacing([2, 0, 4, 0]),
    },
    tabHeader: {
        maxWidth: "unset",
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(5)
        },
    }
}));

const tabs = [
    {
        ID: 0,
        name: "Thong_tin_chung",
        icon: "Person",
        data: null,
    },
    {
        ID: 1,
        name: "Thong_tin_lien_he",
        icon: "Phone",
        data: null,
    },
    {
        ID: 2,
        name: "Thong_tin_gia_dinh",
        icon: "Groups",
        data: null,
    },
    {
        ID: 3,
        name: "Hop_dong_lao_dongU",
        icon: "Paper",
    },
    {
        ID: 4,
        name: "Thong_tin_phep",
        icon: "Calendar",
        data: null,
    },
    {
        ID: 5,
        name: "Tai_lieu_dinh_kem",
        icon: "Attachment",
        data: null,
    },
    {
        ID: 6,
        name: "Lich_su_nhan_su",
        icon: "PersonHistory",
        data: null,
    },
    {
        ID: 7,
        name: "Lich_su_BHXH",
        icon: "InsuranceHistory",
        data: null,
    },
    {
        ID: 8,
        name: "Danh_gia",
        icon: "Assessment",
        data: null,
    },
    {
        ID: 9,
        name: "Lich_su_dao_tao",
        icon: "School",
        data: null,
    },
    {
        ID: 10,
        name: "Ho_so_bao_hiem",
        icon: "FolderInsurance",
        data: null,
    },
    {
        ID: 120,
        name: "Thong_tin_luong",
        icon: "Money",
        info: [
            {mode: 121, permission: "W09F2000_BS"},
            {mode: 122, permission: "W09F2000_BC"},
            {mode: 123, permission: "W09F2000_BL"},
        ],
        data: null,
    },
];
class W09F2000 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            key: 0,
            activeTab: '1',
            selected: false,
            iPermissions: {},
            infoEmployee: null,
            tab0: true,
            loading: null,
            loadingCboEmployee: false,
            dataCboEmployees: {
                rows: [],
                totals: 0
            },
            rowData: {},
            showW09F2210Popup: false,
            Employee: "",
        };
        this.filterCboEmployees = {
            strSearch: "",
            skip: 0,
            limit: 50
        };
        this.scrollTop = 0;
        this.tabOpening = false;
        this.tabOpened = {
            tab0: true,
        };
        this.clickScroll = false;
        this.scrolling = false;
        this.ref = {};
        this.await = false;
        this.timerClickScroll = 0;
        this.timerOnScroll = 0;
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission(["W09F5700", "W09F2000_BS", "W09F2000_BC", "W09F2000_BL"], (arrPer) => {
            let iPermissions = {};
            if(Array.isArray(arrPer)) {
               iPermissions = arrPer.reduce((obj, item) => {
                    obj[item.FormID] = item.IsPermission;
                    return obj
                }, {});
            }
            this.setState({iPermissions: iPermissions});
        }, true);
    };

    loadInfo = async (mode, type, cb, skip,  limit, isAwait) => {
        let {EmployeeID} = this.getInfo();
        const {Employee} = this.state;
        let params = {
            FormID: "W09F2000",
            EmployeeID : Employee || EmployeeID,
            Language: Config.language || "84",
            Mode: mode || 0,
            Type: type || "",
            skip: skip || 0,
            limit: limit || 10,
        };
        this.await = !!isAwait;

        this.setState({loading: true});
        return new Promise((resolve => {
            this.props.W09f2000Actions.getInfo(params, (errors, data) => {
                this.setState({loading: false});
                if (errors) {
                    Config.popup.show('ERROR', errors);
                    return false;
                }
                if (mode === 1) {
                    const employee = Config.getUser({EmployeeID: data?.EmployeeID ?? ""});
                    this.setState({infoEmployee: {...data, UserPictureURL: employee?.UserPictureURL || ""}});
                }
                cb && cb(errors, data);
                return resolve(data);
            })
        }));
    };

    loadCboEmployees = (isReset) => {
        const {skip, limit, strSearch} = this.filterCboEmployees;
        const params = {
            HostID: "",
            FormID: "W09F2225",
            Language: Config.language || '84',
            Mode: 0,
            skip: skip,
            limit: limit,
            search: strSearch
        };

        this.setState({loadingCboEmployee: true});
        this.props.generalActions.getCboEmployees(params, (error, data) => {
            this.setState({loadingCboEmployee: false});
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if (data) {
                const {dataCboEmployees} = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total: total
                    }
                })
            }
        });
    };

    componentDidMount = async () => {
        await this.loadPermission();
        // const permission = this.state.iPermission?.find(i => i.FormID === 'W09F2000')?.IsPermission ?? 0;
        // if (permission <= 0) return;
        this.loadCboEmployees();
        let colRight = document.getElementById('colRight');
        colRight.addEventListener('scroll', this.onScroll);
    };

    componentWillUnmount = () => {
        let colRight = document.getElementById('colRight');
        colRight.removeEventListener('scroll', this.onScroll);
    };

    onScroll = () => {
        let colRight = document.getElementById('colRight');
        const {key} = this.state;
        const direction = this.scrollTop < colRight.scrollTop ? "down" : "up";
        // this.timerOnScroll setTimeout 200s:  để check user ko scroll nữa thì sử lí logic
        if(this.timerOnScroll) clearTimeout(this.timerOnScroll);
        this.timerOnScroll = setTimeout(() => {
            // this.timerClickScroll setTimeout 200s:  để check user khi click tab và scroll đến tab đó xong thì xet this.clickScroll = false
            if(this.timerClickScroll) clearTimeout(this.timerClickScroll);
            this.timerClickScroll = setTimeout(() => {
                if (this.clickScroll && this.ref["tab" + this.state.key]) {
                    this.clickScroll = false;
                }
            }, 200);
            if (this.ref && !this.clickScroll) {
                this.scrolling = true;
                const items = Object.keys(this.ref);
                for(let item of items) {
                    if (this.ref[item]?.element) {
                        const scrollY = direction === "down" ? colRight.clientHeight + (colRight.scrollTop - 150) : colRight.clientHeight + colRight.scrollTop + 200;
                        if (scrollY >= this.ref[item]?.element.offsetTop) {
                            if (!this.tabOpening) {
                                const ID = item.substring(3, item.length);
                                // check nếu scroll lên ID > key tab active thì bỏ qua id đó rồi for tiếp
                                if(direction === "up" && Number(ID) > key) continue;
                                this.handleChange(null, Number(ID), direction);
                                if (!this.tabOpened[item]) {
                                    this.onExpandTab(Number(ID));
                                    break;
                                }
                            }
                        }

                    }
                }
                this.scrolling = false;
            }
        }, 200);
        this.scrollTop = colRight.scrollTop;
    };

    getInfo = () => {
        const {location} = this.props;
        const url = new window.URLSearchParams(window.location.search);
        if (url && url.get('EmployeeID')) {
            return {
                EmployeeID: url.get('EmployeeID'),
            }
        }
        else if (location && location.state) {
            const isInProfile = location.state?.isInProfile || false;
            return {
                isInProfile: isInProfile,
                EmployeeID: isInProfile ? Config.getHREmployeeID() : location.state.EmployeeID,
                FormID: location.state.FormID,
            }
        }
        else {
            return {
                EmployeeID: Config.getHREmployeeID(),
                isClickPersonal: true
            }
        }
    };

    handleChange = (evt, value, direction) => {
        let colRight = document.getElementById('colRight');
        const heightAccordionSummary = 56;
        const {key} = this.state;
        if (key !== value) {
            const scrolling = this.scrolling;
            // check nếu scroll xuống thì ko xét tab trước
            if(direction === "down" && value < key) return;
            this.setState({key: value}, () => {
                if (!this.tabOpened["tab" + value]) {
                    this.onExpandTab(value, () => {
                        if (this.ref["tab" + value] && !scrolling) {
                            this.clickScroll = true;
                            colRight.scrollTo({top: this.ref["tab" + value]?.element.offsetTop - heightAccordionSummary, behavior: 'smooth'});
                        }
                    });
                } else {
                    if (this.ref["tab" + value] && !scrolling) {
                        this.clickScroll = true;
                        colRight.scrollTo({top: this.ref["tab" + value]?.element.offsetTop - heightAccordionSummary, behavior: 'smooth'});
                    }
                }
            });
        }
    };


    handleChangeEmployee = (key, e) => {
        if(!key) return false;
        if(key === "EmployeeID" && e?.value !== "") {
            this.setState({Employee: e?.value || ""}, () => {
                this.handleChange(null, 0);
                if (this.refs) {
                    Object.keys(this.refs).forEach(ref => {
                        if (this.refs[ref]) this.refs[ref].loadGrid();
                    });
                }
            });
        }
    };

    onExpandTab = (ID, cb) => {
        if (!ID && ID !== 0) return false;
        if (!this.state.loading && !this.await) {
            this.tabOpened["tab" + ID] = true;
            this.tabOpening = true;
            this.setState({["tab" + ID]: !(this.state["tab" + ID] || false)}, () => {
                setTimeout(() => {
                    cb && cb();
                    this.tabOpening = false;
                }, 300);
            });
        }
    };

    redirectScreen = () => {
        const {Employee} = this.state;
        browserHistory.push({
            pathname: Config.getRootPath() + "W09F2002",
            state: {
                EmployeeID: Employee || Config.profile.HREmployeeID
            }
        });
    };

    onHistory = () => {
        this.redirectScreen()
    };

    renderButtonTab = (e) => {
        return (
            e.visible && <Button variant={'outlined'} color={'default'} onClick={() => e.onClick()}
                                 style={{minWidth: 20, padding: 12}}>
                <Icon className={e.direction === 'left' ? 'fas fa-chevron-left' : 'fas fa-chevron-right'}/>
            </Button>
        );
    };

    onBack = () => {
        const {FormID} = this.getInfo();
        if (FormID)
            browserHistory.goBack();
        else
            browserHistory.push(Config.getRootPath() + "W09F2225");
    };

    renderContent = (ID) => {
        let permission = 0;
        let {EmployeeID, FormID, isClickPersonal, isInProfile} = this.getInfo();
        const {Employee, iPermissions} = this.state;
        const isHidden =  (isInProfile || isClickPersonal || FormID === "W09F2225") ? "" : "hidden";
        EmployeeID = Employee || EmployeeID;
        if (!ID && ID !== 0) return null;
        switch (ID + 1) {
            case 1:
                return this.tabOpened["tab" + ID] ? <W09F2000Employees ref={"tab" + ID} mode={ID + 1} employeeID={EmployeeID} isHidden={isHidden} onLoad={this.loadInfo}/> : <></>;
            case 2:
                return this.tabOpened["tab" + ID] ? <W09F2000Personal ref={"tab" + ID} mode={ID + 1} employeeID={EmployeeID} isHidden={isHidden} onLoad={this.loadInfo}/> : <></>;
            case 3:
                return this.tabOpened["tab" + ID] ? <W09F2000Family ref={"tab" + ID} mode={ID + 1} employeeID={EmployeeID} onLoad={this.loadInfo}/> : <></>;
            case 4:
                return this.tabOpened["tab" + ID] ? <W09F2000Contracts ref={"tab" + ID} mode={ID + 1} onLoad={this.loadInfo}/> : <></>;
            case 5:
                return this.tabOpened["tab" + ID] ? <W09F2000Leave ref={"tab" + ID} mode={ID + 1} onLoad={this.loadInfo} employeeID={EmployeeID}/> : <></>;
            case 6:
                return this.tabOpened["tab" + ID] ? <W09F2000Attachments ref={"tab" + ID} mode={ID + 1} onLoad={this.loadInfo}/> : <></>;
            case 7:
                return this.tabOpened["tab" + ID] ? <W09F2001HR mode={ID + 1} EmployeeID={EmployeeID}/> : <></>;
            case 8:
                return this.tabOpened["tab" + ID] ? <W09F2000HistorySocialInsurance ref={"tab" + ID} mode={ID + 1} onLoad={this.loadInfo}/> : <></>;
            case 9:
                return this.tabOpened["tab" + ID] ? <W09F2000Evaluation  ref={"tab" + ID} mode={ID + 1} onLoad={this.loadInfo}/> : <></>;
            case 10:
                return this.tabOpened["tab" + ID] ? <W09F2000TrainingHistory  ref={"tab" + ID} mode={ID + 1} onLoad={this.loadInfo}/> : <></>;
            case 11:
                permission = iPermissions?.W09F5700 || 0;
                return this.tabOpened["tab" + ID] ? <W09F2000Insurrance ref={"tab" + ID} mode={ID + 1} onLoad={this.loadInfo} permission={permission > 0 || EmployeeID === Config.getHREmployeeID()}  /> : <></>;
            case 121:
                return this.tabOpened["tab" + ID] ? <W09F2000SalaryInfo mode={ID + 1} EmployeeID={EmployeeID} permissions={iPermissions} isInProfile={isInProfile}/>  : <></>;
            default:
                break;
        }
        return <div>{Config.lang("Khong_co_du_lieu")}</div>;
    };

    onAdd = () => {
        const {Employee} = this.state;
        const {EmployeeID} = this.getInfo();
        const dataEmployee = Employee || EmployeeID;
        this.setState({rowData: {
                mode: "add",
                propertyID: "RELATIVE",
                OldPropertyID: "",
                RelativeID: "",
                IGE: "",
                employeeID: dataEmployee
            },showW09F2210Popup: true});
    };

    onCloseModal = () => {
        this.setState({showW09F2210Popup: false});
    };


    render() {
        const {infoEmployee, loading, key, dataCboEmployees, loadingCboEmployee, Employee, showW09F2210Popup, rowData} = this.state;
        // const permission = iPermission?.find(i => i.FormID === 'W09F2000')?.IsPermission ?? 0;
        // if (permission <= 0) return null;
        const {isMatchMediaQuery} = this.props;
        const {EmployeeID, FormID} = this.getInfo();
        const classes = useStyles();
        const isHidden = EmployeeID === Config.getHREmployeeID() ?  "" : (FormID === "W09F2225") ? "" : "hidden";
        return (
            <React.Fragment>
                {showW09F2210Popup && <W09F2210 open={showW09F2210Popup}
                                                onClose={() => this.onCloseModal()}
                                                mode={"edit"}
                                                rowData={rowData}/>}
                    <ActionToolbar
                        className={classes.toolbar}
                        title={Config.lang("Chi_tiet_nhan_vien")}
                        onBack={this.onBack}
                        alignment={"flex-end"}
                    >
                        <div className={"display_row align-center valign-middle"}>
                            <Button
                                label={Config.lang("Lich_su_DCTT")}
                                color={"primary"}
                                viewType={"filled"}
                                className={isHidden}
                                size={Config.isMobile ? "small" : "medium"}
                                startIcon={"History"}
                                onClick={this.onHistory}
                            />
                        </div>
                    </ActionToolbar>
                <Loading visible={loading} fixed/>
                <Row>
                    <Col xs={2} sm={3}>
                        <TabContainer
                            direction={"vertical"}
                            tabIndex={key}
                        >
                            <TabHeader className={classes.tabHeader}>
                                {!isMatchMediaQuery &&
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Dropdown
                                            className={classes.dropDown}
                                            dataSource={dataCboEmployees.rows}
                                            total={dataCboEmployees.total}
                                            skip={this.filterCboEmployees.skip}
                                            limit={this.filterCboEmployees.limit}
                                            displayExpr={"{EmployeeID} - {EmployeeName}"}
                                            valueExpr={"EmployeeID"}
                                            valueObjectDefault={
                                                typeof infoEmployee === "object" ? infoEmployee : null
                                            }
                                            viewType={"outlined"}
                                            value={Employee || infoEmployee}
                                            loading={loadingCboEmployee}
                                            disabled={FormID !== "W09F2225"}
                                            clearAble
                                            onChange={e => this.handleChangeEmployee("EmployeeID", e)}
                                            iconExpr={{key: 'UserPictureURL', prefix: Config.getCDNPath()}}
                                            renderSelectedItem={"EmployeeName"}
                                            onInput={(e) => {
                                                this.filterCboEmployees.strSearch = e.target.value;
                                                this.filterCboEmployees.skip = 0;
                                                this.loadCboEmployees(true);
                                            }}
                                            onLoadMore={(e) => {
                                                this.filterCboEmployees.skip = e.skip;
                                                this.filterCboEmployees.limit = e.limit;
                                                this.loadCboEmployees();
                                            }}
                                        />
                                    </Col>
                                    {/*<Col xs={3} sm={3} md={3} lg={3}>*/}
                                    {/*    <UserImage data={infoEmployee} allowHover={false} width={46} height={46}/>*/}
                                    {/*</Col>*/}
                                    {/*<Col xs={9} sm={9} md={9} lg={9}>*/}
                                    {/*    <div style={{fontSize: '12px',fontWeight: "bold"}}>*/}
                                    {/*        <UserName data={infoEmployee} allowHover={false}/>*/}
                                    {/*    </div>*/}
                                    {/*</Col>*/}
                                </Row>
                            }
                            {tabs && tabs.map(tab => {
                                const icon = <Icon name={tab.icon}/>;
                                return <TabItem
                                    key={tab.ID}
                                    index={tab.ID}
                                    className={classes.tabItem}
                                    startIcon={icon}
                                    onClick={() => this.handleChange(null, tab.ID)}
                                    label={Config.lang(tab.name)}
                                />
                            })}

                            </TabHeader>
                        </TabContainer>
                    </Col>
                    <Col id={"colRight"} xs={10} sm={9} className={classes.tabContent}>
                        <AccordionGroup  collapseOther>
                            {tabs && tabs.map(tab => {
                                return (
                                    <Accordion
                                        key={tab.ID}
                                        ref={ref => (this.ref["tab" + tab.ID] = ref)}
                                        expanded={this.state["tab" + tab.ID] || false}
                                        onExpand={() => this.onExpandTab(tab.ID)}
                                        onCollapse={() => this.onExpandTab(tab.ID)}
                                    >
                                        <AccordionSummary>
                                            <div className={"display_row align-center"}>
                                                <Typography type={"h2"} uppercase>
                                                    {Config.lang(tab.name) || ""}
                                                </Typography>
                                                <Button  label={Config.lang('Them')}
                                                         viewType={'filled'}
                                                         color={"primary"}
                                                         className={isHidden || ((tab.ID === 2) ? "mgl8x" : "hidden")}
                                                         startIcon={'add'}
                                                         style={{ textTransform: 'uppercase' }}
                                                         size={"tiny"}
                                                         onClick={this.onAdd}
                                                />
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {this.renderContent(tab.ID)}
                                        </AccordionDetails>
                                    </Accordion>
                                );
                            })}
                        </AccordionGroup>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

}

export default compose(connect((state) => ({
        getInfo: state.W09F2000.getInfo
    }),
    (dispatch) => ({
        generalActions: bindActionCreators(generalActions, dispatch),
        W09f2000Actions: bindActionCreators(W09f2000Actions, dispatch),
    })
))(withCustomHook(W09F2000));

