import React, {useState, useRef, useEffect, forwardRef} from "react";
import {Column} from "devextreme-react/data-grid";
import GridContainer from "../../../grid-container/grid-container";
import Config from "../../../../config";
import _ from "lodash";
import {TabContainer, TabHeader, TabItem, TabPanel, Col, Row} from 'diginet-core-ui/components';
import UserImage from "../../../common/user/user-image";
import UserName from "../../../common/user/user-name";

const W09F2000Evaluation = forwardRef(({mode, onLoad}, ref) => {
    const [dataEvaluation, setDataEvaluation] = useState({rows: [], total: 0});
    const [tabIndex, setTabIndex] = useState(0);

    const paging = useRef({skip: 0, limit: 4});
    let {skip, limit} = paging.current;
    const dataTabItem = [Config.lang("Danh_gia_nhan_vien_het_han_HDLD"), Config.lang("Danh_gia_nhan_vien_dinh_ky")]

    useEffect(() => {
        loadGrid(tabIndex + 1);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleStringsConcatenation = (characters = "", ...value) => _.join(_.compact(value), characters);

    const loadGrid = async (n) => {
        await onLoad((mode * 10) + n, "", (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            const rows = _.get(data, "rows", data);
            const total = _.get(data, "total", _.size(data))
            setDataEvaluation({rows, total});
        }, skip, limit);
    };

    const handleChangePage = (page) => {
        skip = page * limit;
        loadGrid(tabIndex + 1)
    };

    const handleChangePerPage = (perPage) => {
        skip = 0;
        limit = perPage;
        loadGrid(tabIndex + 1)
    };

    const handleChangeTab = (index) => {
        skip = 0;
        limit = 4;
        setTabIndex(index);
        loadGrid(index + 1)
    };

    const renderColumnsEmpEvaExpires = () => {
        return (<GridContainer
                dataSource={dataEvaluation.rows}
                totalItems={dataEvaluation.total}
                itemPerPage={limit}
                skipPerPage={skip}
                onChangePage={handleChangePage}
                onChangePerPage={handleChangePerPage}
                pagerFullScreen={false}
                typePaging={"remote"}
                loading={false}
            >
                <Column caption={Config.lang("Ngay_danh_gia")} dataField="AssessDate"/>
                <Column caption={Config.lang("Loai_danh_gia")} dataField="TransTypeName"/>
                <Column caption={Config.lang("Loai_hop_dong_lao_dong")} dataField="WorkFormName"/>
                <Column caption={Config.lang("Ghi_chu")} dataField="Note"/>
            </GridContainer>
        )
    };

    const renderColumnEmpEvaPeriodically = () => {

        return (<GridContainer
            dataSource={dataEvaluation.rows}
            totalItems={dataEvaluation.total}
            itemPerPage={limit}
            skipPerPage={skip}
            onChangePage={handleChangePage}
            onChangePerPage={handleChangePerPage}
            pagerFullScreen={false}
            typePaging={"remote"}
            loading={false}
        >
            <Column caption={Config.lang("Nguoi_tao")} cellRender={({data}) => {
                return <div className={"display_row align-center pdt10 pdb10"}>
                    <UserImage valueExpr={'UserID'}
                               keyExpr={"CreateUserID"}
                               data={data}
                    />
                    <div className={"w29f2040_info"}>
                        <div style={{fontSize: '1.12rem'}}>
                            <UserName valueExpr={'UserID'}
                                      keyExpr={"CreateUserID"}
                                      data={data}
                            />
                        </div>
                    </div>
                </div>
            }}/>
            <Column caption={Config.lang("Ngay_danh_gia")} dataField="VoucherDate"/>
            <Column caption={Config.lang("Loai_danh_gia")} dataField="TransTypeName"/>
            <Column caption={Config.lang("Chu_ky")}
                    cellRender={({data}) => {
                        const performancePeriod = _.get(data, "PerformancePeriod", "");
                        const performanceDateFrom = _.get(data, "PerformanceDateFrom", "");
                        const performanceDateTo = _.get(data, "PerformanceDateTo", "");
                        return (`${handleStringsConcatenation(",",
                            performancePeriod ? performancePeriod + " " + Config.lang("Thang") : false,
                            handleStringsConcatenation(" - ", performanceDateFrom, performanceDateTo)
                        )}`)
                    }
                    }/>
            <Column caption={Config.lang("Ghi_chu")} dataField="Note"/>
        </GridContainer>)
    };

    return <div style={{width: "100%"}}>
        <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
                <TabContainer
                    color="#7F828E"
                    id="tab-Container"
                    value={tabIndex}
                >
                    <TabHeader
                        style={{
                            justifyContent: 'space-between'
                        }}
                    >
                        {dataTabItem.map((item, idx) =>
                            <TabItem
                                key={idx}
                                index={idx}
                                label={item}
                                onClick={() => handleChangeTab(idx)}
                            />
                        )}
                    </TabHeader>
                    {dataTabItem.map((item, idx) =>
                        <TabPanel key={idx} index={idx}/>
                    )}
                </TabContainer>
                {!!tabIndex ? renderColumnEmpEvaPeriodically() : renderColumnsEmpEvaExpires()}
            </Col>
        </Row>
    </div>
});

export default W09F2000Evaluation;