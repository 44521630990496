import React, {useState, useRef, useEffect, forwardRef} from "react";
import {Column} from "devextreme-react/data-grid";
import GridContainer from "../../../grid-container/grid-container";
import Config from "../../../../config";
import _ from "lodash";
import {Row, Col} from "diginet-core-ui/components";

const W09F2000TrainingHistory = forwardRef(({mode, onLoad}, ref) => {
    const [dataHistory, setDataHistory] = useState({rows: [], total: 0});
    const paging = useRef({skip: 0, limit: 4});
    let {skip, limit} = paging.current;

    useEffect(() => {
        loadGrid();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleStringsConcatenation = (characters = "", ...value) => _.join(_.compact(value), characters);

    const handleChangePage = (page) => {
        skip = page * limit;
        loadGrid()
    };

    const handleChangePerPage = (perPage) => {
        skip = 0;
        limit = perPage;
        loadGrid()
    };

    const loadGrid = async () => {
        await onLoad(mode, "", (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            const rows = _.get(data, "rows", data);
            const total = _.get(data, "total", _.size(data))
            setDataHistory({rows, total});
        }, skip, limit);
    };

    const renderTrainingTime = ({data}) => handleStringsConcatenation(" - ", data?.FromDate, data?.ToDate)

    return <div style={{width: "100%"}}>
        <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
                <GridContainer
                    dataSource={dataHistory.rows}
                    totalItems={dataHistory.total}
                    itemPerPage={limit}
                    skipPerPage={skip}
                    onChangePage={handleChangePage}
                    onChangePerPage={handleChangePerPage}
                    pagerFullScreen={false}
                    typePaging={"remote"}
                    loading={false}
                >
                    <Column caption={Config.lang("Ke_hoach_dao_tao")} dataField={"TrainingPlanName"}/>
                    <Column caption={Config.lang("Khoa_dao_tao")} dataField={"TrainingCourseName"}/>
                    <Column caption={Config.lang("Nguoi_dao_tao")} dataField={"TrainningEmpName"}/>
                    <Column caption={Config.lang("Thoi_gian_dao_tao")} cellRender={renderTrainingTime}/>
                    <Column caption={Config.lang("Dia_diem")} dataField={"TrainingDivisionName"}/>
                    <Column caption={Config.lang("Ket_qua")} dataField={"Result"}/>
                </GridContainer>
            </Col>
        </Row>
    </div>
});

export default W09F2000TrainingHistory;